const host = window.location.host;
export let BASE_URL_TRANSACTION;
export let BASE_URL_USER;
switch (true){
    case host.includes("localhost"):
        BASE_URL_TRANSACTION = "https://dev-trx.requite.tech";
        BASE_URL_USER = "https://dev-user.requite.tech";
        break;
    case host.includes("dev-community-portal"):
        BASE_URL_TRANSACTION = "https://dev-trx.requite.tech";
        BASE_URL_USER = "https://dev-user.requite.tech";
        break;
    case host.includes("dev-tapntip"):
        BASE_URL_TRANSACTION = "https://dev-trx.requite.tech";
        BASE_URL_USER = "https://dev-user.requite.tech";
        break;
    case host.includes("dev-tapndonate"):
        BASE_URL_TRANSACTION = "https://dev-trx.requite.tech";
        BASE_URL_USER = "https://dev-user.requite.tech";
        break;
    case host.includes("community-portal"):
        BASE_URL_TRANSACTION = "https://trx.requite.tech";
        BASE_URL_USER = "https://user.requite.tech";
        break;
    case host.includes("tapntip"):
        BASE_URL_TRANSACTION = "https://trx.requite.tech";
        BASE_URL_USER = "https://user.requite.tech";
        break;
    case host.includes("tapndonate"):
        BASE_URL_TRANSACTION = "https://trx.requite.tech";
        BASE_URL_USER = "https://user.requite.tech";
        break;
    default:
        BASE_URL_TRANSACTION = "https://dev-trx.requite.tech";
        BASE_URL_USER = "https://dev-user.requite.tech";
        break;
}
