import React, { useEffect, useState } from "react";
import "./stripedetails.css";
import SidebarStripe from "../../components/SideBar/SidebarStripe";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { accountStatusInfoAction, memberInfoAction, refreshTokenAction } from "../../redux/api/api";
import { Box, CircularProgress } from "@mui/material";
import SelectCommunity from "../../components/SelectCommunity/SelectCommunity";
import Footer from "../../components/Footer/Footer";

const StripeDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [communitiesData, setCommunitiesData] = useState();
  const [selectedCommunity, setSelectedCommunity] = useState(false);
  const [allverifydata, setAllVerifyData] = useState();
  const [allDataCommunity, setAllDataCommunity] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [accountStatusData, setAccountStatusData] = useState();
  const [stripeurl, setStripeUrl] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = localStorage.getItem("accesstoken");

  useEffect(() => {
    if (accessToken) {
      handleRefreshToken();
      fetchMemberData();
      fetchAccountStatus();
    }
    if (accountStatusData?.community_id && accountStatusData?.stripe_setup_url) {
      window.location.reload();
      navigate("/dashboard");
    }
  }, [accessToken]);

  useEffect(() => {
    if (accountStatusData?.community_id && accountStatusData?.stripe_setup_url) {
      navigate("/dashboard");
    }
  }, [accountStatusData]);

  useEffect(() => {
    if (accessToken) {
      handleRefreshToken();
    }
  }, [accessToken, accountStatusData?.currently_due]);

  const fetchMemberData = async () => {
    try {
      const response = await dispatch(memberInfoAction({ accessToken }));
      if (memberInfoAction.fulfilled.match(response)) {
        const communities = response.payload.communities;
        setCommunitiesData(communities);
        setAllVerifyData(response.payload);
        if (communities?.length > 0) {
          const firstCommunity = communities[0];
          setSelectedCommunity(firstCommunity?.community_id);
          setAllDataCommunity({
            ...response.payload,
            communities: firstCommunity,
          });
        }
      } else {
        setErrorMessage(
          response.payload?.message || "Failed to fetch member info"
        );
      }
    } catch (error) {
      setErrorMessage("An unexpected error occurred.");
    }
  };

  const fetchAccountStatus = async () => {
    setIsLoading(true);
    try {
      const response = await dispatch(accountStatusInfoAction({ accessToken }));
      if (accountStatusInfoAction.fulfilled.match(response)) {
        setAccountStatusData(response?.payload);
        setStripeUrl(response?.payload.stripe_setup_url);
      } else {
        setErrorMessage(
          response.payload?.message || "Failed to fetch account status info"
        );
      }
    } catch (error) {
      setErrorMessage("An unexpected error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRefresh = async () => {
    fetchAccountStatus();
  };

  const handleRefreshToken = async () => {
    if (accountStatusData?.currently_due && accountStatusData?.currently_due?.length === 0) {
      try {
        const refresh_token = localStorage.getItem("refreshToken");
        const response = await dispatch(refreshTokenAction({ refresh_token }));
        if (refreshTokenAction.fulfilled.match(response)) {
          const new_access_token = response.payload?.access_token;
          const new_refresh_token = response.payload?.refresh_token;
          localStorage.removeItem("accesstoken");
          localStorage.setItem("accesstoken", new_access_token);
          localStorage.setItem("refreshToken", new_refresh_token);
        } else {
          console.error(
            "Failed to refresh token:",
            response.payload?.message || "Unknown error"
          );
        }
      } catch (error) {
        console.error("Failed to refresh token:", error);
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (accessToken) {
        fetchAccountStatus();
      }
    }, 40000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
     <div className="stripDetailMainPage mainLayoutPage">
      <SidebarStripe />
      <div className="dashboard-container borderFormat">
          <div className="headerStripeOnBoard">
          <div className="selectedCommunity">
          </div>
          <SelectCommunity />
          </div>
          <div className="heading-content-stripe-pending">Build your profile!</div>
          <div className="StripeAccountPendingStatus">
             <div className="subHeadingText">Requite shall now collect below information from you - when you have these details handy, click on Continue onboarding button which
              will open another window. <br></br> You can any time come back to this screen and check the progress by clicking Review Progress button.
             </div>
             <div className="accountSetup backgroundImage">
                {allDataCommunity?.communities?.stripe_setup_url === null ? (
                  navigate("/dashboard")
                ) : (
                  <>
                  <div style={{display:'flex',justifyContent:'space-between'}}>
                    <a href={accountStatusData?.generated_link} target="_blank" rel="noreferrer" className="onboardButton">
                      Continue onboarding
                    </a>
                    <div style={{ marginTop: '2px', marginBottom: '2px' }}>
                      <button className="refreshButton"  onClick={() => {
                        handleRefresh();
                        handleRefreshToken();
                      }}>Review Progress</button>
                    </div>
                  </div>
                  {isLoading && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '2vh'}}><CircularProgress /></Box>}
                   <div style={{width:'100', display:'flex', justifyContent:'space-between'}}>
                    <div className="listOfPendingData">
                      {accountStatusData?.currently_due?.map((item, index) => (
                        <div key={index}>{item}</div>
                      ))}
                    </div>
                      </div>
                  </>
                )}
              </div>
              <div>
            </div>
          </div>
          <div style={{marginTop:"20px"}}>
           <Footer />
          </div>
         </div>
    </div>
    </>
  );
};

export default StripeDetails;
