import React from "react";


const NotFound = () => {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <h1 style={{color:'red'}}>404 - Page Not Found</h1>
        <p style={{color:'red'}}>Sorry, the page you are looking for does not exist.</p>
      </div>
    );
  };

export default NotFound  
  