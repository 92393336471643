import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../apiClient/apiClient";
import { BASE_URL_TRANSACTION, BASE_URL_USER } from "./BaseURL/BaseURL";

//===================================================== New Flow Start =======================================================================
export const loginWithMobileAction = createAsyncThunk(
  "auth/loginMobile",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(
        `${BASE_URL_USER}/api/v2/user/generate-otp`,
        formData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const otpAction = createAsyncThunk(
  "auth/otp",
  async (otpData, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(
        `${BASE_URL_USER}/api/v2/auth/login-with-otp`,
        otpData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const memberInfoAction = createAsyncThunk(
  "auth/memberInfo",
  async ({ accessToken }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        `${BASE_URL_USER}/api/v1/community-member/member-info`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const accountStatusInfoAction = createAsyncThunk(
  "status/AccountInfo",
  async ({ accessToken }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        `${BASE_URL_USER}/api/v1/community-member/account-status-info`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const stripeOnBoarding = createAsyncThunk(
  "signup/bussiness",
  async ({ accessToken, payload }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(
        `${BASE_URL_USER}/api/v1/community-member/update-community-member`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCommunityList = createAsyncThunk(
  "list/communitylist",
  async ({ accessToken, community_id }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        `${BASE_URL_USER}/api/v1/community-member/${community_id}/get-all-community-professional`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const addCommunityProfessionalAction = createAsyncThunk(
  "add/professional",
  async ({ accessToken, community_id, payload }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(
        `${BASE_URL_USER}/api/v1/community-member/${community_id}/add-community-professional`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const UpdateUserDetailAction = createAsyncThunk(
  "update/updateuser",
  async ({ accessToken, community_id, payload }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(
        `${BASE_URL_USER}/api/v1/community-member/${community_id}/add-community-professional`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const activeInactiveStaffUserAction = createAsyncThunk(
  "userstaff/activeInactive",
  async ({ accessToken, community_id, member_id }, { rejectWithValue }) => {
    try {
      const response = await apiClient.put(
        `${BASE_URL_USER}/api/v1/community-member/${community_id}/professional-status/${member_id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const refreshTokenAction = createAsyncThunk(
  "login/refreshtoken",
  async (refresh_token, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(
        `${BASE_URL_USER}/api/v2/auth/refreshtoken`,
        refresh_token,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Unknown error occurred");
    }
  }
);

export const UpdateMemberInfoAction = createAsyncThunk(
  "user/userUpdate",
  async ({ accessToken, payload }, { rejectWithValue }) => {
    try {
      const response = await apiClient.put(
        `${BASE_URL_USER}/api/v1/community-member/update-member-info`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPaymentListAction = createAsyncThunk(
  'list/paymentlist',
  async ({ accessToken , community_id, member_id, start_date, end_date }, { rejectWithValue }) => {
    try {
      const timeSpanOffset = new Date().getTimezoneOffset();
      const response = await apiClient.get(
        `${BASE_URL_TRANSACTION}/api/v1/payment/${community_id}/member-payment/${member_id}?start_date=${start_date}&end_date=${end_date}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "timezone-offset": `${timeSpanOffset}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getCommunityDashboardAction = createAsyncThunk(
  "list/dashboardpaymentlist",
  async (
    { accessToken, community_id, dateLocal },
    { rejectWithValue }
  ) => {
    try {
      const timeSpanOffset = new Date().getTimezoneOffset();
      const response = await apiClient.get(
        `${BASE_URL_TRANSACTION}/api/v1/payment/${community_id}/community-dashboard?current_date=${dateLocal}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "timezone-offset": `${timeSpanOffset}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getCommunityDashboardGraphAction = createAsyncThunk(
  "Graph/dashboardpaymentGraph",
  async ({ accessToken, community_id, trending_day }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        `${BASE_URL_TRANSACTION}/api/v1/payment/${community_id}/community-dashboard?trending_day=${trending_day}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getTipSummaryHistoryAction = createAsyncThunk(
  "list/tipsummary",
  async (
    { accessToken, community_id, member_id, timezoonoffset },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        `${BASE_URL_TRANSACTION}/api/v1/payment/${community_id}/member-tip-summary/${member_id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "timezone-offset": `${timezoonoffset}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getCommunityReaderAction = createAsyncThunk(
  "list/communityReader",
  async ({ accessToken, community_id }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        `${BASE_URL_USER}/api/v1/reader/get-all-community-reader/${community_id}?page_number=1&limit=5000`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const ActiveInactiveDeviceAction = createAsyncThunk(
  "device/activeinactive",
  async ({ accessToken, community_id, reader_id }, { rejectWithValue }) => {
    try {
      const response = await apiClient.put(
        `${BASE_URL_USER}/api/v1/reader/${community_id}/reader-status/${reader_id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addDeviceAction = createAsyncThunk(
  "add/device",
  async ({ accessToken, community_id, payload }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(
        `${BASE_URL_USER}/api/v1/reader/${community_id}/add-community-reader`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const UpdateReaderAction = createAsyncThunk(
  "device/updateReader",
  async (
    { accessToken, reader_id, community_id, payload },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.put(
        `${BASE_URL_USER}/api/v1/reader/${community_id}/update-reader/${reader_id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSettingAction = createAsyncThunk(
  "list/settinglist",
  async ({ accessToken, community_id }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        `${BASE_URL_USER}/api/v1/community-member/${community_id}/community-setting`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const UpdateCommunityTipAmountAction = createAsyncThunk(
  "update/tipAmount",
  async ({ accessToken, community_id, payload }, { rejectWithValue }) => {
    try {
      const response = await apiClient.put(
        `${BASE_URL_USER}/api/v1/community-member/${community_id}/tip-amount`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getReaderHistoryAction = createAsyncThunk(
  "list/readerhistory",
  async ({ accessToken, community_id, reader_id }, { rejectWithValue }) => {
    try {
      const timeSpanOffset = new Date().getTimezoneOffset();
      const response = await apiClient.get(
        `${BASE_URL_TRANSACTION}/api/v1/payment/${community_id}/reader-history/${reader_id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "timezone-offset": `${timeSpanOffset}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

//===================================================== New Flow End =======================================================================

export const getCommunityPaymentDoc = createAsyncThunk(
  "payment/download",
  async (
    {
      accessToken,
      community_id,
      startDateUTCConvert,
      endDateUTCConvert,
      timezoonoffset,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        `${BASE_URL_TRANSACTION}/api/v1/payment/${community_id}/download-payment?start_date=${startDateUTCConvert}&end_date=${endDateUTCConvert}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "timezone-offset": `${timezoonoffset}`,
          },
          responseType: "blob",
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getCommunityPaymentDocForMember = createAsyncThunk(
  "payment/download",
  async (
    {
      accessToken,
      community_id,
      startDateUTCConvert,
      endDateUTCConvert,
      timezoonoffset,
      member_id,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        `${BASE_URL_TRANSACTION}/api/v1/payment/${community_id}/download-payment?member_id=${member_id}&start_date=${startDateUTCConvert}&end_date=${endDateUTCConvert}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "timezone-offset": `${timezoonoffset}`,
          },
          responseType: "blob",
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const sendSMSOnMobileNOToCommunityMember = createAsyncThunk(
  "communityMember/sendlink",
  async ({ accessToken, mobileNo }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(
        `${BASE_URL_USER}/api/v1/community-member/send-app-download-link`,
        { mobile: mobileNo },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: "blob",
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const updateTipAmountSettingCommunityMember = createAsyncThunk(
  "communityMember/update",
  async ({ accessToken, community_id, payload }, { rejectWithValue }) => {
    try {
      const response = await apiClient.put(
        `${BASE_URL_USER}/api/v1/community-member/${community_id}/tip-amount`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const updatePayoutConfigSettingCommunityMember = createAsyncThunk(
  "communityMember/updatepayout-config",
  async ({ accessToken, community_id, payload }, { rejectWithValue }) => {
    try {
      const response = await apiClient.put(
        `${BASE_URL_USER}/api/v1/community-member/${community_id}/payout-config`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getCommunityConnectedReader = createAsyncThunk(
  "communityMember/updatepayout-config",
  async ({ accessToken, community_id }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        `${BASE_URL_USER}/api/v1/reader/${community_id}/connected-reader`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getCommunityStaffCount = createAsyncThunk(
  "communityMember/updatepayout-config",
  async ({ accessToken, community_id }, { rejectWithValue }) => {
    try {
      const timeSpanOffset = new Date().getTimezoneOffset();
      const response = await apiClient.get(
        `${BASE_URL_USER}/api/v1/community-member/${community_id}/get-community-staff-count`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "timezone-offset": `${timeSpanOffset}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getCommunity1staffTodayTipSummary = createAsyncThunk(
  "communityMember/updatepayout-config",
  async ({ accessToken, community_id }, { rejectWithValue }) => {
    try {
      const timeSpanOffset = new Date().getTimezoneOffset();
      const response = await apiClient.get(
        `${BASE_URL_TRANSACTION}/api/v1/payment/${community_id}/community-staff`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "timezone-offset": `${timeSpanOffset}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getDeviceStateCount = createAsyncThunk(
  "device/deviceCount",
  async ({ accessToken, community_id }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        `${BASE_URL_USER}/api/v1/reader/${community_id}/community-device-stats`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getManagePayoutList = createAsyncThunk(
  "payout/managepayout",
  async ({ accessToken, community_id }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        `${BASE_URL_TRANSACTION}/api/v1/payout/${community_id}/community-payouts-list?page=1&limit=100`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getBankDetailParticularUser = createAsyncThunk(
  "bank/bankDetailUser",
  async ({ accessToken, community_id, member_id }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        `${BASE_URL_USER}/api/v1/community-member/${community_id}/get-member-bank-account/${member_id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const addBankAccountDetailAction = createAsyncThunk(
  "add/addbank",
  async ({ accessToken, payload }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(
        `${BASE_URL_USER}/api/v1/community-member/create-bank-account`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBankDetailProfile = createAsyncThunk(
  "bank/bankProfile",
  async ({ accessToken }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        `${BASE_URL_USER}/api/v1/community-member/get-bank-account`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const communityLogoAction = createAsyncThunk(
  "communitylogo/communitylogo",
  async ({ accessToken, community_id, payload }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(
        `${BASE_URL_USER}/api/v1/${community_id}/upload-community-logo/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const communityAssetBgAction = createAsyncThunk(
  "communitybgWall/communitybgWall",
  async ({ accessToken, community_id, payload }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(
        `${BASE_URL_USER}/api/v1/${community_id}/upload-community-asset/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const profilePictureUploadAction = createAsyncThunk(
  "profile/profilePicture",
  async ({ accessToken, payload }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(
        `${BASE_URL_USER}/api/v1/upload-profile-picture/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const communityGenerateQRCodeAction = createAsyncThunk(
  "QRCODE/generateqrcode",
  async ({ accessToken, community_id }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(
        `${BASE_URL_USER}/api/v1/generate-community-qr-code/?community_id=${community_id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCommunityMember = createAsyncThunk(
  "getMember/getMember",
  async ({ accessToken, community_id, member_id }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        `${BASE_URL_USER}/api/v1/community-member/${community_id}/community-member/${member_id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getallTipsSummary = createAsyncThunk(
  "alltip/alltipssummary",
  async (
    {
      accessToken,
      community_id,
      startDateUTCConvert,
      endDateUTCConvert,
      timezoonoffset,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        `${BASE_URL_TRANSACTION}/api/v1/payment/${community_id}/community-tips?sdate=${startDateUTCConvert}&edate=${endDateUTCConvert}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "timezone-offset": `${timezoonoffset}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getAddressBranch = createAsyncThunk(
  "address/address",
  async ({ accessToken, community_id }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        `${BASE_URL_USER}/api/v1/community-member/${community_id}/get-community-branches`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getHelpAndSupport = createAsyncThunk(
  "helpsupport/helpandsupport",
  async ({ accessToken }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        `${BASE_URL_USER}/api/v1/help-and-support`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getTipReceipt = createAsyncThunk(
  "tipreceipt/tipreceipt",
  async ({ accessToken, tip_id, timezoonoffset }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        `${BASE_URL_TRANSACTION}/api/v1/payment/${tip_id}/tip-receipt`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "timezone-offset": `${timezoonoffset}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getEligiblePayout = createAsyncThunk(
  "payouteligible/EligiblePayout",
  async ({ accessToken, community_id }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        `${BASE_URL_TRANSACTION}/api/v1/payout/${community_id}/eligiable-payout-transaction`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const previewPayoutAction = createAsyncThunk(
  "previewPayout/previewPayout",
  async ({ accessToken, community_id, payload }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(
        `${BASE_URL_TRANSACTION}/api/v1/payout/${community_id}/preview-payout`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPayoutListHistoryAction = createAsyncThunk(
  "payoutlist/payoutlisthistory",
  async ({ accessToken, community_id, startDate, endDate, timezoonoffset }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        `${BASE_URL_TRANSACTION}/api/v1/payout/${community_id}/community-payouts?trending_day=0&start_date=${startDate}&end_date=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "timezone-offset": `${timezoonoffset}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getPayoutChartAction = createAsyncThunk(
  "payoutchart/payoutchart",
  async ({ accessToken, community_id }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        `${BASE_URL_TRANSACTION}/api/v1/payout/${community_id}/community-payouts?trending_day=90`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getCommunityTipSummaryChartTrendAction = createAsyncThunk(
  "sumarytrend/sumarytrend",
  async ({ accessToken, community_id }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        `${BASE_URL_TRANSACTION}/api/v1/payment/${community_id}/community-tips-summary`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getDeletePayoutAction = createAsyncThunk(
  "deletepayout/deletepayout",
  async ({ accessToken, community_id, payout_id }, { rejectWithValue }) => {
    try {
      const response = await apiClient.delete(
        `${BASE_URL_TRANSACTION}/api/v1/payout/${community_id}/payout/${payout_id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getPayoutStaffIndividualListAction = createAsyncThunk(
  "individualPayout/individualPayout",
  async (
    { accessToken, community_id, startDate , endDate, timezoonoffset },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(
        `${BASE_URL_TRANSACTION}/api/v1/payout/${community_id}/staff-payout-by-date?start_date=${startDate}&end_date=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "timezone-offset": `${timezoonoffset}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

export const getPartialPayoutAction = createAsyncThunk(
  'partialPayout/partialPayout',
  async ({ accessToken , community_id }, { rejectWithValue }) => {
    try {
      const timeSpanOffset=new Date().getTimezoneOffset();

      const response = await apiClient.get(
        `${BASE_URL_TRANSACTION}/api/v1/payout/${community_id}/partial-payout-transaction`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'timezone-offset':`${timeSpanOffset}`
          },
        }
      );
      return response.data;

    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);



export const AddAddressAction = createAsyncThunk(
  'addaddress/addaddress',
  async ( { accessToken , community_id , payload } , { rejectWithValue }) => {
    try {
      const response = await apiClient.post(
        `${BASE_URL_USER}/api/v1/member-address/${community_id}/add-community-address`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addBankAccountDetailCommunityAction = createAsyncThunk(
  "addcommunity/addbankcommunity",
  async ({ accessToken, community_id ,payload }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(
        `${BASE_URL_USER}/api/v1/community-member/${community_id}/create-bank-account`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



export const accountOnBoardingAction = createAsyncThunk(
  "accountonboarding/accountonboarding",
  async ({ accessToken, payload }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(
        `${BASE_URL_USER}/api/v1/community-member/update-community-member-v2`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const updateStaffBankDetailAction = createAsyncThunk(
  "staffbankUpdate/staffbankUpdate",
  async ({ accessToken, bank_id, payload  }, { rejectWithValue }) => {
    try {
      const response = await apiClient.put(`${BASE_URL_USER}/api/v1/community-member/bank/${bank_id}/update`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const communityBankAccountAddAction = createAsyncThunk(
  "accountonboarding/accountonboarding",
  async ({ accessToken, community_id, payload }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(
        `${BASE_URL_USER}/api/v1/community-member/${community_id}/create-bank-account`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const tipShareReceiptAction = createAsyncThunk(
  "tipsharereceipt/tipsharereceipt",
  async ({ accessToken, payload }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(
        `${BASE_URL_TRANSACTION}/api/v1/payment/share-receipt`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


