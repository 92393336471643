import React from "react";

const Pagination = ({ currentPage, totalPages, onPrevious, onNext }) => {
  return (
    <div className="pagination mb-4">
      <button
        onClick={onPrevious}
        disabled={currentPage === 1}
        className="nextPageButton"
        style={{
          backgroundColor: currentPage === 1 ? "#ccc" : "#00c2d1",
          borderRadius: "8px",
          cursor: currentPage === 1 ? "not-allowed" : "pointer",
        }}>
        Previous
      </button>
      <span style={{ paddingTop: "5px", margin: "0 10px" }}>
        {`Page ${currentPage} / ${totalPages}`}
      </span>
      <button
        onClick={onNext}
        disabled={currentPage === totalPages}
        className="nextPageButton"
        style={{
          backgroundColor: currentPage === totalPages ? "#ccc" : "#00c2d1",
          borderRadius: "8px",
          cursor: currentPage === totalPages ? "not-allowed" : "pointer",
        }}>
        Next
      </button>
    </div>
  );
};

export default Pagination;
