import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material";
import { addCommunityProfessionalAction } from "../../redux/api/api";
import { useNavigate } from "react-router-dom";

const countryCodes = [
  { code: "+1", label: "USA (+1)" },
  { code: "+91", label: "India (+91)" },
];

const permissionName = ["ADMIN_PORTAL_ACCESS", "COLLECT_TIP_ACCESS"];

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 6;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddUserForm = ({ accessToken, community_id, cancelHandler }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addUserData, setAddUserData] = useState({
    name: "",
    mobile_number: "",
    country_code: "+1",
    permission: [],
    email: "",
    functional_role: "",
    shift:null,
  });
  const [errors, setErrors] = useState({});  
  const [success, setSuccess] = useState("");
  const [errormessage, setErrorMessage] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("+1");
  const [loading, setLoading] = useState(false);
  const [touched, setTouched] = useState({});
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
  const handleCountryCodeChange = (e) => {
    const newCountryCode = e.target.value;
    setSelectedCountryCode(newCountryCode);
    setAddUserData((prevData) => ({
      ...prevData,
      country_code: newCountryCode,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile_number") {
      const digitsOnly = value.replace(/[^0-9]/g, "");
      if (digitsOnly.length <= 10) {
        setAddUserData((prevData) => ({ ...prevData, [name]: digitsOnly }));
      }
    } else {
      setAddUserData((prevData) => ({ ...prevData, [name]: value }));
    }

    validateField(name, value);
  };

  const handleSelectChange = (name) => (event) => {
    const value = event.target.value;
    setAddUserData((prevData) => ({
      ...prevData,
      [name]: typeof value === "string" ? value.split(",") : value,
    }));

    if (value.length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const validateField = (name, value) => {
    let error = "";
    if (!value || (Array.isArray(value) && value.length === 0)) {
      error = `${name.replace("_", " ")} is required.`;
    } else if (name === "email" && value && !emailRegex.test(value)) {
      error = "Invalid email format.";
    }
    if (error) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;
  
    Object.keys(addUserData).forEach((key) => {
      const value = addUserData[key];
      if (key === "shift") {
        return;
      }
  
      if (!value || (Array.isArray(value) && value.length === 0)) {
        if (key !== "email") {
          newErrors[key] = `${key.replace("_", " ")} is required.`;
          isValid = false;
        }
      } else if (key === "email" && value && !emailRegex.test(value)) {
        newErrors[key] = "Invalid email format.";
        isValid = false;
      }
    });
  
    setErrors(newErrors);
    return isValid;
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (!isValid) return;
    setLoading(true);
    const payload = {
      ...addUserData,
      mobile_number: `${addUserData.country_code}${addUserData.mobile_number}`,
      shift:null
    };
    try {
      const response = await dispatch(addCommunityProfessionalAction({ accessToken, community_id, payload }));
      if (addCommunityProfessionalAction.fulfilled.match(response)) {
        toast.success("Form submitted successfully!");
        setTimeout(() => navigate("/manage-staff"), 2000);
      } else {
        toast.error(response.payload?.detail || "Failed to submit form.");
      }
    } catch (error) {
      toast.error("An error occurred while submitting the form.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
    <div className="row popupHeight">
      <div className="columnview">
        <div className="form-group p-2">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={addUserData.name}
            onChange={handleInputChange}
            placeholder="Enter Staff Name"
          />
          {errors.name && <span style={{ color: "red",textTransform:'capitalize' }}>{errors.name}</span>}
        </div>
        <div className="form-group p-2">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            className="form-control"
            name="email"
            value={addUserData.email}
            onChange={handleInputChange}
            placeholder="Enter Staff email"
          />
          {errors.email && (
            <div className="invalid-feedback">{errors.email}</div>
          )}
        </div>
        <div className="form-group p-2">
          <label htmlFor="functionalRole">Functional Role</label>
          <input
            type="text"
            className="form-control"
            name="functional_role"
            value={addUserData.functional_role}
            onChange={handleInputChange}
            placeholder="Enter Staff functional role"
          />
          {errors.functional_role && (
            <span style={{ color: "red", textTransform:'capitalize'  }}>{errors.functional_role}</span>
          )}
        </div>
      </div>

      <div className="columnview">
        <div className="form-group p-2">
          <label htmlFor="mobile_number">Mobile Number</label>
          <div style={{ display: "flex", alignItems: "center" }}>
            <select
              value={selectedCountryCode}
              onChange={handleCountryCodeChange}
              className="form-control countryCode"
              style={{height:'40px'}}>
              {countryCodes.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.label}
                </option>
              ))}
            </select>
            <input
              type="text"
              className="form-control"
              name="mobile_number"
              value={addUserData.mobile_number}
              onChange={handleInputChange}
              placeholder="Enter Staff mobile number"
              maxLength={10} />
          </div>
         {errors.mobile_number && (
            <span style={{ color: "red",textTransform:'capitalize' }}>{errors.mobile_number}</span>
          )}
        </div>
        <div className="form-group p-2">
          <label htmlFor="permission">Permission</label>
          <FormControl sx={{  width: "100%" }} error={touched.permission && errors.permission}>
              <InputLabel id="demo-multiple-checkbox-label">Select</InputLabel>
            <Select
              multiple
              value={addUserData.permission}
              onChange={handleSelectChange("permission")}
              onBlur={() =>
                setTouched({ ...touched, permission: true })
              }
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
              input={<OutlinedInput label="Select" />}
              sx={{ height: 40 }}>
              {permissionName.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={addUserData.permission.includes(name)} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
            {errors.permission && (
              <span style={{ color: "red",textTransform:'capitalize' }}>{errors.permission}</span>
            )}
          </FormControl>
        </div>
      </div>
    </div>
    <div className="row">
    <div class="button-container">
      <button class="cancelledAddedUser" onClick={cancelHandler}>Cancel</button>
      <button class="submit-button" disabled={loading}>
            {loading ? "Submitting..." : "Submit"}</button>
    </div>
    </div>
    {success && <div className="success-message">{success}</div>}
    {errormessage && (
      <div className="error-message">{errormessage}</div>
    )}
  </form>
  );
};

export default AddUserForm;
