import React, { useEffect, useRef, useState } from "react";
import "./dashboard.css";
import SideBar from "../../components/SideBar/SideBar";
import DateRangePicker from "../../components/date/date";
import { useDispatch, useSelector } from "react-redux";
import { getCommunityDashboardAction, memberInfoAction, getCommunityPaymentDoc, getCommunityConnectedReader, getAddressBranch, getTipReceipt, getEligiblePayout, getCommunityTipSummaryChartTrendAction,tipShareReceiptAction } from "../../redux/api/api";
import { useNavigate } from "react-router-dom";
import SelectCommunity from "../../components/SelectCommunity/SelectCommunity";
import LineChart from "../../components/TrendChart/TrendChart";
import rightIcon from "../../assets/images/rightIcon.png"
import arrowleft from "../../assets/images/arrow-left.png"
import userProfileIcon from "../../assets/images/user-profile-pic.png"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../App.css";
import userinvite from "../../assets/images/userinvite.png";
import downloadcircle from "../../assets/images/downloadcircle.png";
import AddUserForm from "../../components/UserForm/AddUserForm";
import PopupDialog from "../../components/Popup/PopupDialog";
import Footer from "../../components/Footer/Footer";
import { useReactToPrint } from "react-to-print";
import printerIcon from "../../assets/images/printer_icon.png"
import { convertToUTCDate, convertToUTCTime } from "../../Utility/Utility";
import headerRequiteLogo from "../../assets/images/requite.png"
import tapNTipLogo from "../../assets/images/tapNtipBlackLogo.png"
import PayoutChart from "../../components/PayoutChart/PayoutChart";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [communitiesData, setCommunitiesData] = useState([]);
  const [allVerifyData, setAllVerifyData] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [allDataCommunity, setAllDataCommunity] = useState();
  const [requestStatus, setRequestStatus] = useState();
  const accessToken = localStorage.getItem("accesstoken");
  const community_id = localStorage.getItem("selectedCommunity");
  const { paymentList } = useSelector((state) => state.GetDashboardPaymentStore);
  const { connectedReader } = useSelector((state) => state.GetCommunityConnectedReaderStore);
  const { PayoutData } = useSelector((state) => state.GetPayoutStore);
  const { communityTrendSummaryData } = useSelector((state) => state.GetCommunityTipSummaryStore);
  const [receiptHtml, setReceiptHtml] = useState('');
  const [shareTipUrl, setShareTipUrl] = useState('');
  const [iframeError, setIframeError] = useState(false);

  
  const [topPerformers, setTopPerformers] = useState([]);
  const [recentTransactions, setTransactions] = useState([]);
  const [startDate, setStartDate] = useState([]);
  const [endDate, setEndDate] = useState([]);
  const [connectedReaderDate, setConnectedReaderDate] = useState()
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const listRef = useRef(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [timePeriod, setTimePeriod] = useState("90"); 
  const [payoutTimePeriod, setPayoutTimePeriod] = useState("90"); 
  const { addressData } = useSelector((state) => state.GetAddressDetailStore);
  const [localAddressData, setLocalAddressData] = useState([]);
  const [isModalOpenHistory, setModalOpenHistory] = useState(false);
  const [tipDataReceipt, setTipDataReceipt] = useState()
  const { tipListData } = useSelector((state) => state.GetTipReceiptStore);
  const [eligibleDataPayout, setEligibleDataPayout] = useState()
  const [communityChartTrendData,setCommunityChartTrendData] = useState()
  

  const formatToYYYYMMDD = (date) => {
    const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    const year = adjustedDate.getFullYear();
    const month = String(adjustedDate.getMonth() + 1).padStart(2, "0");
    const day = String(adjustedDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  
  const formattedStartDate = startDate ? formatToYYYYMMDD(new Date(startDate)) : null;
  const formattedEndDate = endDate ? formatToYYYYMMDD(new Date(endDate)) : null;

  const handleClickOutside = (event) => {
    if (listRef.current && !listRef.current.contains(event.target)) {
      setSelectedRowIndex(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const convertLocalToUTC = (localDate) => new Date(localDate).toISOString().split("T")[0];

  const getStartOfMonthInUTC = () => {
    const now = new Date();
    return new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 1)).toISOString().split("T")[0];
  };

  const getEndOfMonthInUTC = () => {
    const now = new Date();
    return new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 1, 0)).toISOString().split("T")[0];
  };

  const getStartOfLastMonthInUTC = () => {
    const now = new Date();
    return new Date(Date.UTC(now.getUTCFullYear(), (now.getUTCMonth() - 1), 1)).toISOString().split("T")[0];
  };

  const getEndOfLastMonthInUTC = () => {
    const now = new Date();
    return new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 0)).toISOString().split("T")[0];
  };

  const getstartOfLast7DaysInUTC = () => {
    const now = new Date();
    const date = now.getDate() - 7;
    return new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), date)).toISOString().split("T")[0];
  };

  const getstartOfLast14DaysInUTC = () => {
    const now = new Date();
    const date = now.getDate() - 14;
    return new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), date)).toISOString().split("T")[0];
  };

  const handleOpenDialog = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const handleCloseDialog = () => {
    setIsPopupOpen(false);
  };
  const getCurrentDateInUTC = () => new Date().toISOString().split("T")[0];

  const systemTimeSpanOffset = new Date().getTimezoneOffset();

   useEffect(() => {
    if (accessToken) {
      fetchMemberData();
    }
  }, [accessToken, dispatch]);

  const fetchMemberData = async () => {
    try {
      const response = await dispatch(memberInfoAction({ accessToken, timezoonoffset: systemTimeSpanOffset }));
      if (memberInfoAction.fulfilled.match(response)) {
        setRequestStatus(response?.meta?.requestStatus);
        const communities = response?.payload?.communities || [];
        setCommunitiesData(communities);
        setAllVerifyData(response?.payload);
        if (communities?.length > 0) {
          setAllDataCommunity({
            ...response?.payload,
            communities: communities[0],
          });
        }
      } else {
        throw new Error(response?.payload?.detail || "Failed to fetch member info");
      }
    } catch (error) {
      toast.error(error.message);
      setErrorMessage(error.message);
    }
  };

  const downloadCSV = async (startDate, endDate, fileName) => {
    try {
      if (!startDate || !endDate) {
        toast.error("Start date and end date are required.");
        return;
      }

      const start = new Date(startDate);
      const end = new Date(endDate);

      if (isNaN(start) || isNaN(end)) {
        toast.error("Invalid date format. Please provide valid dates.");
        return;
      }

      if (start > end) {
        toast.error("Start date cannot be after end date.");
        return;
      }
      const response = await dispatch(
        getCommunityPaymentDoc({
          accessToken,
          community_id,
          startDateUTCConvert: startDate,
          endDateUTCConvert: endDate,
          timezoonoffset: systemTimeSpanOffset,
        })
      );

      if (response.error) {
        throw new Error("Failed to fetch payment documents.");
      }

      const url = window.URL.createObjectURL(new Blob([response.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      toast.error(error.message);
      setErrorMessage(error.message);
    }
  };


  const downloadCSVChooseDate = async (startDate, endDate, fileName) => {
    try {
      if (!startDate || !endDate) {
        toast.error("Start date and end date are required.");
        return;
      }

      const start = new Date(startDate);
      const end = new Date(endDate);

      if (isNaN(start) || isNaN(end)) {
        toast.error("Invalid date format. Please provide valid dates.");
        return;
      }

      if (start > end) {
        toast.error("Start date cannot be after end date.");
        return;
      }
      const response = await dispatch(
        getCommunityPaymentDoc({
          accessToken,
          community_id,
          startDateUTCConvert: formattedStartDate,
          endDateUTCConvert: formattedEndDate,
          timezoonoffset: systemTimeSpanOffset,
        })
      );

      if (response.error) {
        throw new Error("Failed to fetch payment documents.");
      }

      const url = window.URL.createObjectURL(new Blob([response.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      toast.error(error.message);
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    let intervalId;

    if (community_id && accessToken) {
      const fetchData = () => {
        dispatch(getCommunityDashboardAction({
          accessToken,
          community_id,
          dateLocal: convertLocalToUTC(new Date())
        }));
      };
      fetchData();
      intervalId = setInterval(fetchData, 2 * 60 * 1000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [community_id, accessToken, dispatch]);


  useEffect(() => {
    if(paymentList){
      const performers = paymentList?.top_performing_staff?.map((staff) => ({
        member_id: staff?.member_id,
        name: staff?.name,
        amount: (staff?.total_tip || 0) / 100,
        profile_url:staff?.profile_url
      }));
      setTopPerformers(performers || []);
    }
  }, [paymentList]);

  useEffect(() => {
    const transactions = paymentList?.latest_5_tips?.map((tips) => ({
      tip_id: tips.tip_id,
      payment_read_method: tips?.payment_read_method,
      transaction_ts:tips?.transaction_ts,
      transaction_time: tips?.transaction_time,
      transaction_status:tips?.transaction_status,
      card_type:tips?.card_type,
      name:tips?.name,
      total_fees_deducted:(tips?.total_fees_deducted) / 100,
      psp_fees:(tips?.psp_fees) / 100,
      amount: (tips?.amount || 0) / 100,
    })) || [];
    setTransactions(transactions?.slice(0, 3));
  }, [paymentList]);

  const totalTip = (paymentList?.total_tip || 0) / 100;

  const handleStaffClick = () => {
    navigate("/manage-staff");
  };

  const handleNameClick = (staff) => {
    navigate("/tip-history", { state: { staff } });
  };

  const handleDeviceClick = () => {
    navigate("/manage-device");
  };

  const handleTipsTransaction = () => {
    navigate("/tips-summary-list")
  }

  const handleDateRangeChange = (startDate, endDate) => {
    try {
      const formatToMMDDYYYY = (date) => {
        const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
        const month = String(adjustedDate.getMonth() + 1).padStart(2, "0");
        const day = String(adjustedDate.getDate()).padStart(2, "0");
        const year = adjustedDate.getFullYear();
        return `${month}-${day}-${year}`;
      };
  
      const formattedStartDate = startDate ? formatToMMDDYYYY(new Date(startDate)) : null;
      const formattedEndDate = endDate ? formatToMMDDYYYY(new Date(endDate)) : null;
      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);
    } catch (error) {
      console.error("Invalid date format:", error);
    }
  };

  useEffect(() => {
    const fetchReader = () => {
      if (accessToken && community_id) {
        dispatch(
          getCommunityConnectedReader({
            accessToken,
            community_id,
          })
        );
      }
    };
    fetchReader();
    const intervalId = setInterval(fetchReader, 2 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [dispatch, accessToken, community_id]);


  useEffect(() => {
    if(connectedReader){
      setConnectedReaderDate(connectedReader)
    }
  },[connectedReader])

  const componentRef = useRef();
  const handleSavePdf = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Transaction_Details_${
      selectedTransaction?.name || "Unknown"
    }.pdf`, 
  });

  useEffect(() => {
    if (accessToken && community_id) {
      dispatch(getAddressBranch({ accessToken, community_id }));
    }
  }, [accessToken, community_id, dispatch]);

  useEffect(() => {
    if (addressData) {
      setLocalAddressData(addressData);
    }
  }, [addressData]);


  // const handleRowClick = (tip_id) => {
  //   const systemTimeSpanOffset = new Date().getTimezoneOffset();
  
  //   if(accessToken && community_id){
  //     dispatch(getTipReceipt({
  //       accessToken,
  //       community_id,
  //       tip_id: tip_id,
  //       timezoonoffset: systemTimeSpanOffset,
  
  //     })
  //   );
  //   }
  //   setModalOpenHistory(true); 
  // };
      const handleRowClick = async (tip_id) => {
        if (accessToken) {
          try {
            // Dispatch the action to Redux
            const response = await dispatch(
              tipShareReceiptAction({
                accessToken,
                payload: {
                  tip_id,
                  email: "user@example.com",
                  mobile: "string"
                }
              })
            );
            if (response?.payload?.['share-tip-url']) {
              setShareTipUrl(response.payload['share-tip-url']);
              setModalOpenHistory(true);
            }
          } catch (error) {
            console.error('Error fetching receipt:', error);
          }
        }
      };

  useEffect(() => {
      if (tipListData) {
        setTipDataReceipt(tipListData);
      }
    }, [tipListData]);

  useEffect(() => {
        if (accessToken && community_id) {
          dispatch(getEligiblePayout({ accessToken, community_id }));
        }
  }, [accessToken, community_id, dispatch]);
    
  useEffect(() => {
        if (PayoutData) {
         setEligibleDataPayout(PayoutData);
        }
  }, [PayoutData]);

  useEffect(() => {
        if (accessToken && community_id) {
          dispatch(getCommunityTipSummaryChartTrendAction({ accessToken, community_id }));
        }
  }, [accessToken, community_id, dispatch]);
    
  useEffect(() => {
        if (communityTrendSummaryData) {
         setCommunityChartTrendData(communityTrendSummaryData);
        }
  }, [communityTrendSummaryData]);

const topPerformerName = communityChartTrendData?.top_performing_staff[0]?.name
const topPerformerTipAmount = communityChartTrendData?.top_performing_staff[0]?.total_tip / 100

return (
    <>
    {paymentList ?
     <>
      <div className="dashboard mainLayoutPage">
        <ToastContainer />
        <SideBar />
        <div className="dashboard-container borderFormat">
          <div className="header">
            <div style={{ display: "flex", flexDirection: "column", gap: "5px" }} >
              <div className="heading-content">Hello: {allDataCommunity?.name}</div>
              <div className="subheading-content">Welcome back to SimplyTip</div>
            </div>
            <div className="dropdownMain mainDropdownTablet">
              <div className="mediumScreenHeader">
                <div className="header-button" > <SelectCommunity /></div>
                <button className="onboard-button" onClick={handleOpenDialog}>
                  <span>Onboard New Staff</span>
                  <img src={userinvite} alt="userInvite"></img>
                </button>
              </div>
            </div>
          </div>
          <div className="horizental-Line"></div>
          <div className="pageContent" >
            <div className="activityMain">
              <div className="summaryTip">
                <div className="leaderboard-header subContentHeader">
                  <p className="subheader" style={{paddingTop:'15px'}}>Today's Summary</p>
                </div>
                <div className="subContentBody">
                  <div className="activityList summary-content" >
                    <span className="amountTip amount" style={{paddingTop:'5px'}}>${totalTip || "0"}</span> <span className="amountTip amount" style={{paddingTop:'5px'}}>{paymentList?.no_of_tips || "0"}</span>
                  </div>
                  <div className="activityList summary-content " >
                    <div className="details" style={{ width: "58%"}}>Total Tips, with <span className="detailsspan">avg of ${Math.round((totalTip / paymentList?.no_of_tips) * 100 / 100) || "0"}</span></div><div className="details" > Transactions</div>
                  </div>
                </div>
                <div className="todaySummaryFooter">

                 <div className="footer-link" onClick={() => downloadCSV(getCurrentDateInUTC(), getCurrentDateInUTC(), "todays_summary.csv")}>Today's Tips
                  <span style={{paddingLeft:'5px'}} onClick={() => downloadCSV(getCurrentDateInUTC(), getCurrentDateInUTC(), "todays_summary.csv")}>
                      <img src={downloadcircle} alt="downloadcircle" className="downloadImageSummary" />
                  </span>
                 </div>
                 <div className="footer-link" style={{paddingLeft:'20px'}} onClick={() => downloadCSV(getStartOfMonthInUTC(), getEndOfMonthInUTC(), "Current_Month_Report.csv")}>Last 30 days 
                  <span style={{paddingLeft:'5px'}} onClick={() => downloadCSV(getStartOfMonthInUTC(), getEndOfMonthInUTC(), "Current_Month_Report.csv")} >
                    <img src={downloadcircle} alt="downloadcircle" className="downloadImageSummary" />
                  </span>
                  </div> 
                 <div className="footer-link" style={{paddingLeft:'20px'}} onClick={handleTipsTransaction}>Transactions
                  <span style={{paddingLeft:'5px'}}  >
                    <img src={rightIcon}  alt="rightIcon" className="downloadImageSummary" />
                  </span>
                  </div>
                </div>
              </div>

              <div className="systemStatusAlert">
                <div className="leaderboard-header subContentHeaderMin">
                  <div>
                    <p className="subheader" style={{paddingTop:'10px'}}>System Status</p>
                  </div>
                </div>
                <div className="subContentBody60">
                  <div className="activityList">
                    <div className="amount" style={{ paddingLeft: '4px',paddingTop:'11px' }}>
                      {connectedReaderDate?.connected_reader_count || "0"}
                    </div>
                  </div>
                  <div className="activityList details pt-1"> Connected Devices Today </div>
                </div>
                <div className="subContentFooter" style={{ display: "flex", marginBottom: '2px' }} onClick={handleDeviceClick}>
                  <div className="footer-link"> All Devices</div>
                  <div style={{ marginTop: "-4px" }}>
                    <img src={rightIcon} alt="rightIcon" style={{ cursor: 'pointer' }} />
                  </div>
                </div>
                </div>
              <div className="systemStatus">
              <div className="staffLeaderBoard">
                <div className="leaderboard-header">
                  <p className="subheader" style={{paddingTop:'5px'}}>Staff Leaderboard</p>
                </div>
                <div className="subContentBodySecond" ref={listRef}>
                      <ul className="leaderboard-list" style={{paddingTop:'5px'}}>
                        {topPerformers?.length > 0 ? (
                          topPerformers.slice(0,4).map((performer, index) => (
                            <div  key={index} onClick={() => handleNameClick(performer)}
                              className={`store-row ${selectedRowIndex === index ? "selected" : ""}`}  >
                              <div>
                                <img  src={performer?.profile_url ||  userProfileIcon}  alt="ProfileIcon"
                                  style={{ marginRight: "10px", height: "38px", width: "38px", cursor: "pointer",borderRadius:'20px' }}
                                />
                              </div>
                              <div style={{ width: "82%", display: "flex", justifyContent: "space-between" }}>
                                <div style={{ width: "100%" }}>
                                  <div className="store-name" style={{width:'57%'}}>
                                    {performer?.name || "-"}
                                  </div>
                                </div>
                                <div className="store-sales">${performer?.amount.toFixed(2)}</div>
                              </div>
                              <div onClick={() => handleNameClick(performer)} style={{ alignSelf: "baseline" }}>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div>Start using SimplyTip mobile application to accept tips.</div>
                        )}
                      </ul>
                    </div>

                <div className="subContentFooter" style={{paddingTop:'5px'}}>
                  <div style={{ display: "flex"}} onClick={handleStaffClick} >
                    <div className="footer-link">All Staff</div>
                    <div style={{ marginTop: "-4px" }} >
                      <img src={rightIcon} alt="rightIcon" style={{ cursor: 'pointer' }} />
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
            <div className="secondSection">
              <div style={{width:'49%', backgroundColor:'#fff',height:'261px',borderRadius:'8px'}}>
              <div className="trendChart">
                      <div
                        className="leaderboard-header"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          className="subheader"
                          style={{paddingTop:'20px'}}
                        >
                          Tipping Trend
                        </p>
                        <div className="form-group" style={{marginTop:'14px'}}>  
                          <select
                            className="form-control"
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#454545",
                              height: "34px",
                              padding: ".159rem .50rem !Important",
                            }}
                            value={timePeriod}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setTimePeriod(newValue);
                            }}
                          >
                            <option value="90">Last 90 days</option>
                            <option value="30">Last 30 days</option>
                            <option value="14">Last 14 days</option>
                            <option value="7">Last 7 days</option>
                          </select>
                        </div>
                      </div>
                      <div className="chartContent"  style={{ paddingTop: "3px", margin: 0 }} >
                      <LineChart timePeriod={timePeriod}
                          accessToken={accessToken}
                          community_id={community_id} />
                      </div>
                    </div>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                      <div className="TopOfMonth">
                        <div className="topMonthSingle">
                        Top Month
                        </div>
                        <div className="bottomHeaderMonth top-month">
                         ${communityChartTrendData?.top_tips_month_amount /100 || 0 }<br></br>
                          <span className="top-year">{communityChartTrendData?.top_tips_month_name || "NA"}</span>
                        </div>
                      </div>
                     
                      <div className="TopOfMonth">
                        <div className="topMonthSingle ">
                         Top Performer
                        </div>
                        <div className="bottomHeaderMonth top-month">
                        <span 
                          className="top-month" 
                          title={topPerformerName || "NA"}>
                          {topPerformerName 
                            ? `${topPerformerName.slice(0, 6)}${topPerformerName.length > 6 ? '...' : ''}` 
                            : "NA"}
                        </span><br></br>
                          <span className="top-year">${topPerformerTipAmount || 0}</span>
                        </div>
                        </div>
                        <div className="TopOfMonth">
                        <div className="topMonthSingle">
                         Total Tip
                        </div>
                        <div className="bottomHeaderMonth top-month">
                        <span className="top-month">${communityChartTrendData?.total_tip / 100 || 0 }</span><br></br>
                        <span className="top-year">This month</span>
                        </div>
                      </div>
                    </div>
                </div>

                <div style={{width:'49.5%', backgroundColor:'#fff',height:'261px',borderRadius:'8px'}}>
               <div className="trendChartPayout">
                      <div
                        className="leaderboard-header"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}>
                        <p className="subheader" style={{paddingTop:'20px'}}>
                          Payout Trend
                        </p>
                        <div className="form-group" style={{marginTop:'14px'}}>  
                          <select
                            className="form-control"
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#454545",
                              height: "34px",
                              padding: ".159rem .50rem !Important",
                            }}
                            value={payoutTimePeriod}
                            onChange={(e) => {
                              const newValuePayout = e.target.value;
                              setPayoutTimePeriod(newValuePayout);
                            }}>
                            <option value="90">Last 90 days</option>
                            <option value="30">Last 30 days</option>
                            <option value="14">Last 14 days</option>
                            <option value="7">Last 7 days</option>
                          </select>
                        </div>
                      </div>
                      <div className="chartContent"  style={{ paddingTop: "3px", margin: 0 }} >
                      <PayoutChart payoutTimePeriod={payoutTimePeriod} accessToken={accessToken}
                          community_id={community_id} />
                      </div>
                    </div>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div className="TopOfMonth">
                    <div className="topMonthSingle">
                         Payouts Completed
                        </div>
                        <div className="bottomHeaderMonth top-month">
                          ${eligibleDataPayout?.paid_payouts  / 100 || 0}<br></br>
                          <span className="top-year">This month</span>
                        </div>
                      </div>
                      <div className="TopOfMonth">
                        <div className="topMonthSingle ">
                          Payout in Transit
                        </div>
                        <div className="bottomHeaderMonth top-month">
                          <span className="top-month">${eligibleDataPayout?.outstanding_payouts / 100 || 0}</span><br></br>
                          <span className="top-year">Initiated</span>
                        </div>
                        </div>
                        <div className="TopOfMonth">
                        <div className="topMonthSingle ">
                          Payout Eligible
                        </div>
                        <div className="bottomHeaderMonth top-month">
                          <span className="top-month">${eligibleDataPayout?.net_tip_amount /100 || 0 }</span><br></br>
                          <span className="top-year textPayout">Available for Payout</span>
                        </div>
                        </div>
                    </div>
                </div>
             </div>
            <div className="threedSection" style={{ width: "100%", marginTop:'37px'}} >
            <div className="monthly-goal-container">
                <div className="leaderboard-header subContentHeader">
                  <p className="subheader" style={{ paddingTop: '18px' }}>Recent Transactions</p>
                </div>
                <ul className="activity-list subContentBodyWithOutHeader" style={{ paddingTop: '5px' }}>
                  {recentTransactions && recentTransactions.length > 0 ? (
                    recentTransactions.map((transaction, index) => (
                      <li key={index} className="activity-item list-item-recent"                        
                        onClick={() => handleRowClick(transaction.tip_id)}>
                        <div className="card-icon" style={{ position: 'relative', right: '12px' }}>
                          <span style={{ fontWeight: '600' }}>
                            {transaction.payment_read_method === "QRCode" ? (
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="25px"
                                  viewBox="0 -960 960 960"
                                  width="30px"
                                  fill="#5f6368">
                                  <path d="M520-120v-80h80v80h-80Zm-80-80v-200h80v200h-80Zm320-120v-160h80v160h-80Zm-80-160v-80h80v80h-80Zm-480 80v-80h80v80h-80Zm-80-80v-80h80v80h-80Zm360-280v-80h80v80h-80ZM173.33-653.33h133.34v-133.34H173.33v133.34ZM120-600v-240h240v240H120Zm53.33 426.67h133.34v-133.34H173.33v133.34ZM120-120v-240h240v240H120Zm533.33-533.33h133.34v-133.34H653.33v133.34ZM600-600v-240h240v240H600Zm80 480v-120h-80v-80h160v120h80v80H680ZM520-400v-80h160v80H520Zm-160 0v-80h-80v-80h240v80h-80v80h-80Zm40-200v-160h80v80h80v80H400Zm-190-90v-60h60v60h-60Zm0 480v-60h60v60h-60Zm480-480v-60h60v60h-60Z" />
                                </svg>
                              </div>
                            ) : (
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="25px"
                                  viewBox="0 -960 960 960"
                                  width="30px"
                                  fill="#5f6368"
                                >
                                  <path d="M880-733.33v506.66q0 27-19.83 46.84Q840.33-160 813.33-160H146.67q-27 0-46.84-19.83Q80-199.67 80-226.67v-506.66q0-27 19.83-46.84Q119.67-800 146.67-800h666.66q27 0 46.84 19.83Q880-760.33 880-733.33ZM146.67-634h666.66v-99.33H146.67V-634Zm0 139.33v268h666.66v-268H146.67Zm0 268v-506.66 506.66Z" />
                                </svg>
                              </div>
                            )}
                          </span>
                        </div>
                        <div className="transaction-details">
                          <div className="transaction-time">{convertToUTCDate(transaction?.transaction_ts || "NA")}</div>
                          <div className="transaction-time">{transaction?.transaction_time || "NA"}</div>
                          <div className="transaction-amount">${transaction?.amount || 0}</div>
                        </div>
                        <div className="arrow-icon">                         
                          <img src={arrowleft} alt="arrowleft" style={{ width: '20px', height: '20px' }} />
                        </div>
                      </li>
                    ))
                  ) : (
                    <li className="activity-item recentDataNotFound">
                    Data Not Found
                  </li>
                  )}
                </ul>
                <div className="footer-link moreTransaction" onClick={handleTipsTransaction}>More transactions
                  <span style={{paddingLeft:'5px'}} >
                    <img src={rightIcon}  alt="rightIcon" style={{ width: '20px', height: '20px', cursor: 'pointer' }} />
                  </span>
                  </div>
                {/* {isModalOpenHistory && tipListData && (
                        <div className="popup-overlay-history" onClick={() => setModalOpenHistory(false)}>
                          <div className="popup-modal-history" onClick={(e) => e.stopPropagation()}>
                            <div className="modal-header-history"></div>
                            <div className="popupHistoryContainer" ref={componentRef}>
                                <div className="popupHeader">
                                    <div>
                                      {tipListData?.community?.payfor === "Business" ||
                                        tipListData?.community?.payfor === "Businessuser" ? (
                                          <img src={tipListData?.community?.community_logo_url || tapNTipLogo}   alt="Logo" className="logoTipHistoryCommunity" />                                           
                                        ) : (
                                          <img src={tapNTipLogo} alt="logo" style={{width: "151.08px", height: "42.59px", marginLeft: "0px"}} />
                                        )}
                                      </div>
                                    <div className="pt-2">
                                      {tipListData?.community?.payfor === "Business" ||
                                          tipListData?.community?.payfor === "Businessuser" ? (
                                            <div>                                            
                                              <span style={{ fontWeight: "normal" }}>Powered by </span>
                                              <img
                                                src={headerRequiteLogo}
                                                alt="RequiteLogo"
                                                style={{
                                                  width: "80.08px",
                                                  height: "20px",
                                                  opacity: 0.9,
                                                  marginLeft: "0px",
                                                }}
                                              />
                                            </div> 
                                          ) : (
                                            <div
                                              className="profile-name"
                                              style={{ display: "flex", alignItems: "center" }}
                                            >
                                              <span style={{ fontWeight: "normal", marginRight: "5px" }}>
                                                For
                                              </span>
                                              {tipListData?.community?.name}
                                            </div>
                                          )}
                                    </div>
                                </div>
                                <div><hr /></div>
                                <div className="tipReceiptBox">
                                  {tipListData?.community?.payfor === "Solopreneur" && (
                                        <div>
                                          <div className="tipHistoryCommunity">{tipListData?.community?.name}</div>
                                          <div className="payFor">{tipListData?.community?.payfor}</div>
                                          <div className="historyParaTip"> {tipListData?.community?.name} is a preferred service provider on our system. </div>
                                        </div> 
                                      )}
                                      
                                      {tipListData?.community?.payfor === "Businessuser" && (
                                          <div>
                                          <div className="tipHistoryCommunity">{tipListData?.community?.name}</div>
                                          <div className="payFor">{tipListData?.community?.community_name}</div>
                                          <div className="historyParaTip"> {tipListData?.community?.name} is a preferred service provider on our system. </div>
                                          </div> 
                                      )}
                                      {tipListData?.community?.payfor === "Business" && (
                                        <div>
                                        <div className="tipHistoryCommunity">{tipListData?.community?.community_name}</div>
                                        <div className="payFor">{tipListData?.community?.payfor}</div>
                                        <div className="historyParaTip"> Address:{tipListData?.community?.address} </div>
                                        </div> 
                                      )}
                                </div>
                                <div className="mt-2 mb-3"><h4>Tip Receipt</h4></div>
                                <div className="tipDetailHistory">
                                  <div className="historyTipReceipt">
                                    <div>Payment Method:</div>
                                    <div>

                                    {tipListData?.payment_read_method.includes("QRCode") && (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          height="30px"
                                          viewBox="0 -960 960 960"
                                          width="30px"
                                          fill="#5f6368">
                                          <path d="M520-120v-80h80v80h-80Zm-80-80v-200h80v200h-80Zm320-120v-160h80v160h-80Zm-80-160v-80h80v80h-80Zm-480 80v-80h80v80h-80Zm-80-80v-80h80v80h-80Zm360-280v-80h80v80h-80ZM173.33-653.33h133.34v-133.34H173.33v133.34ZM120-600v-240h240v240H120Zm53.33 426.67h133.34v-133.34H173.33v133.34ZM120-120v-240h240v240H120Zm533.33-533.33h133.34v-133.34H653.33v133.34ZM600-600v-240h240v240H600Zm80 480v-120h-80v-80h160v120h80v80H680ZM520-400v-80h160v80H520Zm-160 0v-80h-80v-80h240v80h-80v80h-80Zm40-200v-160h80v80h80v80H400Zm-190-90v-60h60v60h-60Zm0 480v-60h60v60h-60Zm480-480v-60h60v60h-60Z" />
                                        </svg>
                                      )}
                                      {tipListData?.payment_read_method.includes("Card") && (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          height="30px"
                                          viewBox="0 -960 960 960"
                                          width="30px"
                                          fill="#5f6368">
                                          <path d="M880-733.33v506.66q0 27-19.83 46.84Q840.33-160 813.33-160H146.67q-27 0-46.84-19.83Q80-199.67 80-226.67v-506.66q0-27 19.83-46.84Q119.67-800 146.67-800h666.66q27 0 46.84 19.83Q880-760.33 880-733.33ZM146.67-634h666.66v-99.33H146.67V-634Zm0 139.33v268h666.66v-268H146.67Zm0 268v-506.66 506.66Z" />
                                        </svg>
                                      )}

                                    </div>
                                  </div>
                                  <div className="historyTipReceipt pt-1">
                                    <div>Transaction ID:</div>
                                    <div><b>{tipListData?.tip_id.replace(/[^\w\s]/gi, "")}</b></div>
                                  </div>
                                  <div className="historyTipReceipt pt-1">
                                    <div>Transaction Date:</div>
                                    <div><b>{convertToUTCDate(tipListData?.transaction_ts) || "NA"}</b></div>
                                  </div>
                                  <div className="historyTipReceipt pt-1">
                                    <div>Transaction time:</div>
                                    <div><b>{convertToUTCTime(tipListData?.transaction_ts) || "NA"}</b></div>
                                  </div>
                                  <div className="historyTipReceipt pt-1">
                                    <div><b>Transaction status:</b></div>
                                    <div style={{color:'#28a745 '}}><b>{tipListData?.transaction_status || "NA"}</b></div>
                                  </div>
                                </div>
                                <div><hr /></div>
                                <div><h6 className="mt-0 mb-3">Tip Details</h6></div>
                                <div>
                                    <div className="historyTipReceipt">
                                      <div>Tip Paid:</div>
                                      <div><b>${tipListData?.amount / 100 || 0}</b></div>
                                    </div>
                                </div>
                                <div>
                                    <hr />
                                </div>
                                  <div className="receiptThanks">
                                  {tipListData?.community?.payfor === "Solopreneur" && (
                                        <div>
                                          <span className="thanksForSuport">
                                            Thank you for your support !
                                          </span>
                                          <h6 className="mt-0 mb-3 thanksForSupport">Solopreneur</h6>
                                        </div>
                                      )}
                                      {tipListData?.community?.payfor === "Businessuser" && (
                                        <div>
                                          <span className="thanksForSupport">
                                            Thank you for supporting the community!
                                          </span>
                                          <h6 className="mt-0 mb-3 thanksForSupport">{tipListData?.community?.community_name || "NA"}</h6>
                                        </div>
                                      )}
                                      {tipListData?.community?.payfor === "Business" && (
                                        <div>
                                          <span className="thanksForSupport">
                                            Thank you for supporting the community!
                                          </span>
                                          <h6 className="mt-0 mb-3 thanksForSupport" >{tipListData?.community?.community_name || "NA"}</h6>
                                        </div>
                                      )}
                                  </div>

                                <div><hr /></div>
                                <div onClick={handleSavePdf} className="printerTipHistory">
                                  <img src={printerIcon} alt="printerIcon" width={30} height={30}/>
                                </div>
                            </div>  
                          </div>
                        </div>
                      )} */}
                      {isModalOpenHistory && shareTipUrl && (
                        <div className="popup-overlay-receipt" onClick={() => setModalOpenHistory(false)}>
                          <div className="popup-modal-receipt" onClick={(e) => e.stopPropagation()}>
                            <div className="modal-header-receipt"></div>
                            <div className="popupreceiptContainer">
                              <iframe
                                src={shareTipUrl}
                                width="100%"
                                height="650px"
                                overflowY= "auto" // Enables vertical scrolling
                                scrollbarWidth= "thin" // Firefox
                                
                                scrollbarColor= "#888 #f1f1f1"
                                style={{ border: "none", }}
                                
                                onError={() => setIframeError(true)}
                              />
                              {iframeError && <p>Error loading receipt. Please <a href={shareTipUrl} target="_blank">click here</a>.</p>}
                            </div>
                          </div>
                        </div>
                      )}
              </div>

             <div className="Transaction">
              <div className="subheader download-tran">Download Transactions</div>
              <div className="custom-report choose-report-ds">Choose dates for a custom report and click download icon:</div>
                <div style={{display:'flex'}}>
                        <div style={{ marginRight: "8px" }}>
                          <DateRangePicker onDateRangeChange={handleDateRangeChange} />
                        </div>
                        <div onClick={() => downloadCSVChooseDate(startDate, endDate, `report_${startDate}_${endDate}.csv`)} >
                          <img src={downloadcircle} alt="downloadcircle" className="download-Icon-Report" />
                        </div> 
                  </div>      
                <div className="subContentBodyWithOutHeader" style={{paddingTop:'15px'}}>
                  <div className="activityList  summary-content">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} onClick={(e) => {
                      e.stopPropagation(); downloadCSV(getstartOfLast7DaysInUTC(), getCurrentDateInUTC(), "Last_Seven_days_Report.csv")
                    }} className="report-Item" ><div className="footer-link" style={{ marginLeft: '10px' }}>Last seven days</div>
                      <div>
                        <img src={downloadcircle} alt="downloadcircle" className="downloadImageSummary" />
                      </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} onClick={(e) => {
                      e.stopPropagation(); downloadCSV(getstartOfLast14DaysInUTC(), getCurrentDateInUTC(), "Last_Fourteent_Days_Report.csv")
                    }} className="report-Item"><div className="footer-link" style={{ marginLeft: '10px' }}>Last fourteent days</div>
                      <div >
                        <img src={downloadcircle} alt="downloadcircle" className="downloadImageSummary" />
                      </div>
                    </div>
                  </div>
                  <div className="activityList  summary-content">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} onClick={(e) => {
                      e.stopPropagation(); downloadCSV(getStartOfMonthInUTC(), getEndOfMonthInUTC(), "Current_Month_Report.csv")
                    }} className="report-Item"><div className="footer-link" style={{ marginLeft: '10px' }} >Current Month</div>
                      <div>
                        <img src={downloadcircle} alt="downloadcircle" className="downloadImageSummary" />
                      </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }} onClick={(e) => {
                      e.stopPropagation(); downloadCSV(getStartOfLastMonthInUTC(), getEndOfLastMonthInUTC(), "Last_Month_Report.csv")
                    }} className="report-Item"><div className="footer-link" style={{ marginLeft: '10px' }} >Last Month</div>
                      <div >
                        <img src={downloadcircle} alt="downloadcircle" className="downloadImageSummary" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="quickTip">
                <div className="leaderboard-header subContentHeader"> <p className="subheader" style={{paddingTop:'21px'}}>Did you know?</p></div>
                <div className="subContentBody65 custom-report">Using a Requite payment reader increases your Tipping opportunities. 
                  Guests can tap their physical cards or use their soft cards from phone's wallet to make payment!<br></br>
                  Use of Payment reader is absolutely safe and secure - they are configured with IdealTip technology and designed to work only with authorised user's phone.
                </div>
                <div className="subContentFooter" style={{ display: "flex",paddingTop:'10px' }} >
                    <div className="footer-link"><a href="https://www.requite.tech/" target="_blank" rel="noopener noreferrer" style={{color:'#734a00'}}>Learn more</a></div>
                    <div style={{ marginTop: "-4px" }}> <img src={rightIcon} alt="rightIcon" style={{ cursor: 'pointer' }} /> </div>
                  </div>
              </div>
            </div>
            <div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
      <PopupDialog
        open={isPopupOpen}
        onClose={handleCloseDialog}
        title="Onboard New Staff"
      >
        <AddUserForm accessToken={accessToken} community_id={community_id} cancelHandler={handleCloseDialog} />
      </PopupDialog>
     </>
     :<div>Loading...</div>}
   </>
  );
};

export default Dashboard;