import React, { useEffect, useState } from "react";
import "./managedevice.css";
import SideBar from "../../components/SideBar/SideBar";
import Header from "../../components/Header/Header";
import SelectCommunity from "../../components/SelectCommunity/SelectCommunity";
import searchIcon from "../../assets/images/search-icon.png";
import { useNavigate } from "react-router-dom";
import plus from "../../assets/images/plus.png";
import {
  ActiveInactiveDeviceAction,
  getCommunityReaderAction,
  getDeviceStateCount,
} from "../../redux/api/api";
import apiClient from '../../redux/apiClient/apiClient';
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../App.css";
import "../TipHistory/tiphistory.css";
import "../ManageStaff/managestaff.css";
import Footer from "../../components/Footer/Footer";
import userinvite from "../../assets/images/userinvite.png";
import PopupDialog from "../../components/Popup/PopupDialog";
import AddUserForm from "../../components/UserForm/AddUserForm";
import upicon from "../../assets/images/Up.png";
import warningUpdatesoftware from "../../assets/images/warning.png";
import softwareUpdatedIcon from "../../assets/images/softwareUpdatedIcon.png";
import DeviceBox from "../../components/BoxSubHeader/BoxSubHeader";
import { BASE_URL_USER } from "../../redux/api/BaseURL/BaseURL";
import Pagination from "../../components/Pagination/Pagination";

const ManageDevice = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { deviceList } = useSelector((state) => state.GetCommunityReaderStore);
  const { deviceCountTils } = useSelector((state) => state.GetTotalDeviceStore);
  const [deviceData, setDeviceData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("All Status");
  const [sortColumn, setSortColumn] = useState("reader_friendly_name");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const accessToken = localStorage.getItem("accesstoken");
  const community_id = localStorage.getItem("selectedCommunity");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [iframeSrc, setIframeSrc] = useState("");
  const [isReaderPopupOpen, setIsReaderPopupOpen] = useState(false);

  useEffect(() => {
    if (accessToken && community_id) {
      dispatch(getCommunityReaderAction({ accessToken, community_id }));
    }
  }, [dispatch, accessToken, community_id]);

  useEffect(() => {
    if (deviceList) {
      const sortedDeviceList = [...deviceList].sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];
        if (aValue < bValue) return -1;
        if (aValue > bValue) return 1;
        return 0;
      });
      setDeviceData(sortedDeviceList);
    }
  }, [deviceList, sortColumn]);

  const handleStatusChange = async (reader_id, isActive) => {
    const newStatus = !isActive;
    setDeviceData((prevData) =>
      prevData.map((item) =>
        item.reader_id === reader_id
          ? { ...item, status: newStatus ? "ACTIVE" : "INACTIVE" }
          : item
      )
    );

    try {
      const result = await dispatch(
        ActiveInactiveDeviceAction({ accessToken, community_id, reader_id })
      );
      if (ActiveInactiveDeviceAction.fulfilled.match(result)) {
        toast.success(result?.payload?.detail);
      }
    } catch (error) {
      toast.error("Failed to update device status: " + error.detail.msg);
      setDeviceData((prevData) =>
        prevData?.map((item) =>
          item?.reader_id === reader_id
            ? { ...item, status: isActive ? "ACTIVE" : "INACTIVE" }
            : item
        )
      );
    }
  };

  useEffect(() => {
    if (deviceList) {
      const filteredData = deviceList?.filter((item) => {
        const matchesSearchTerm =
          item?.reader_friendly_name?.toLowerCase().includes(searchTerm) ||
          item?.serial_number?.toLowerCase().includes(searchTerm) ||
          item?.software_version?.toLowerCase().includes(searchTerm);
        const matchesStatus =
          statusFilter === "All Status" ||
          (statusFilter === "true" && item?.status === "ACTIVE") ||
          (statusFilter === "false" && item?.status === "INACTIVE");
        return matchesSearchTerm && matchesStatus;
      });

      setDeviceData(filteredData);
      setCurrentPage(1);
    }
  }, [searchTerm, statusFilter, deviceList]);

  const clearFilters = () => {
    setSearchTerm("");
    setStatusFilter("All Status");
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
    const filteredData = deviceList?.filter((item) => {
      const matchesSearchTerm =
        item?.reader_friendly_name?.toLowerCase().includes(searchTerm) ||
        item?.serial_number?.toLowerCase().includes(searchTerm) ||
        item?.software_version?.toLowerCase().includes(searchTerm);

      return matchesSearchTerm;
    });
    setDeviceData(filteredData);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (accessToken && community_id) {
      dispatch(getDeviceStateCount({ accessToken, community_id }));
    }
  }, [dispatch, accessToken, community_id]);

  const handleNameClick = (item) => {
    navigate("/device-detail", { state: { item } });
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentListDevice = deviceData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(deviceData.length / itemsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleOpenDialog = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const handleCloseDialog = () => {
    setIsPopupOpen(false);
  };

  const handleHomeClick = () => {
    navigate("/dashboard");
  };

  const convertToUTC = (dateString) => {
    const localDate = new Date(dateString);
    const timezoneOffset = localDate.getTimezoneOffset();
    const utcDate = new Date(localDate.getTime() - timezoneOffset * 60000);

    const year = utcDate.getUTCFullYear();
    const month = String(utcDate.getUTCMonth() + 1).padStart(2, "0");
    const day = String(utcDate.getUTCDate()).padStart(2, "0");
    const hours = String(utcDate.getUTCHours()).padStart(2, "0");
    const minutes = String(utcDate.getUTCMinutes()).padStart(2, "0");
    return `${month}/${day}/${year} ${hours}:${minutes}`;
  };

  const isDateAfterToday = (date) => {
    const today = new Date();
    const comparedDate = new Date(date);
    return comparedDate > today;
  };

  const handlessotoken = async () => {
    setLoading(true);
    setError(null);
  
    try {
      const response = await apiClient.post(
         `${BASE_URL_USER}/api/v1/community-member/${community_id}/generate-sso-token`
      );
  
      if (response.status === 200) {
        const data = response.data;
        setIframeSrc(data?.reader_order);
        setIsReaderPopupOpen(true);
      } else {
        setError("Failed to generate token");
      }
    } catch (err) {
      if (err.response?.status === 401) {
        setError("Unauthorized access. Please try again.");
      } else {
        setError("An error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCloseReaderDialog = () => {
    setIsReaderPopupOpen(false);
    setIframeSrc("");
  };

  return (
    <>
      <div className="deviceMainPage mainLayoutPage">
        <ToastContainer />
        <SideBar />
        <div className="dashboard-container borderFormat">
          <div className="header">
            <div>
              <div className="titleheading-content">
                Device
                <div className="subtitle">
                  <div className="home-text" onClick={handleHomeClick}>
                    <img src={upicon} alt="upIcon" /> Home /
                  </div>
                  <div className="current-page-text"> Device</div>
                </div>
              </div>
              <div className="tabletScreenHeader">
                <Header />
              </div>
            </div>
            <div className="dropdownMain mainDropdownTablet">
              <div className="mediumScreenHeader">
                <div className="header-button">
                  {" "}
                  <SelectCommunity />
                </div>
                <button className="onboard-button" onClick={handleOpenDialog}>
                  <span>Onboard New Staff</span>
                  <img src={userinvite} alt="logoUser" />
                </button>
              </div>
            </div>
          </div>

          <div className="top-line"></div>

          <div className="deviceBoxMain">
            <DeviceBox
              header="Total Devices"
              subHeading="In Stock at this Community"
              number={deviceCountTils?.total_readers || 0}
            />
            <DeviceBox
              header="Devices in Use"
              subHeading="Active/ Enabled for Tipping"
              number={deviceCountTils?.active_readers || 0}
            />
            <DeviceBox
              header="Pending Firmware Update"
              subHeading="At this Community"
              number={deviceCountTils?.software_update_due || 0}
            />
            <DeviceBox
              header="Device to staff Ratio"
              subHeading="At this Community"
              number={deviceCountTils?.device_staff_ratio || 0}
            />
          </div>
          <div className="deviceTableList">
            <div className="mainTableHeader">
              <div
                className="deviceFilterSection"
                style={{ paddingTop: "14px" }}>
                <input
                  type="text"
                  placeholder="Search name or Serial Number"
                  value={searchTerm}
                  className="form-control"
                  onChange={handleSearch}
                  style={{ width: "400px",height:'40px' }}
                />
                <span className="deviceSearchIcon">
                  <img src={searchIcon} alt="SearchIcon" style={{position:'relative',top:'-5px'}} />
                </span>
                <select
                  className="form-select"
                  onChange={(e) => setStatusFilter(e.target.value)}
                  value={statusFilter}
                  style={{ width: "200px", height: "40px" }}>
                  <option value="All Status">All Status</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
                {(searchTerm != "" ||
                  (statusFilter != "" && statusFilter != "All Status")) && (
                  <button
                    className="deviceClearButton  ms-3"
                    onClick={clearFilters}>
                    Clear Filters
                  </button>
                )}
              </div>
              <div className="mainAddFilter">
                <button onClick={handlessotoken} className="addButton addSpace">
                  <img src={plus} alt="PlusImage" /> Reader Order
                </button>
                {/* Modal */}
              </div>
            </div>
            <div className="table-responsive ">
              <table className="table tableborderedDevice table-hover mt-2">
                <thead className="table-header">
                  <tr>
                    <th scope="col">Sr No</th>
                    <th scope="col">Reader Name</th>
                    <th scope="col">Serial Number</th>
                    <th scope="col">Firmware Version</th>
                    <th scope="col">Firmware Status</th>
                    <th scope="col">Last Used By (Staff Name)</th>
                    <th scope="col">Device Tip Value</th>
                    <th scope="col">Status/Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentListDevice && currentListDevice?.length > 0 ? (
                    currentListDevice?.map((item, index) => (
                      <tr key={item.reader_id}>
                        <td
                          className="td-default-device"
                          style={{ width: "5%", padding: "10px" }}>
                          {index + 1 + indexOfFirstItem}
                        </td>
                        <td
                          className="nameDeviceLink td-default-device"
                          onClick={() => handleNameClick(item)}>
                          {item?.reader_friendly_name || "NA"}
                        </td>
                        <td
                          className="td-default-device"
                          onClick={() => handleNameClick(item)}>
                          {item?.serial_number || "NA"}
                        </td>
                        <td
                          className="td-default-device"
                          onClick={() => handleNameClick(item)}>
                          {item?.software_version || "NA"}
                        </td>
                        <td
                          className="td-default-device"
                          onClick={() => handleNameClick(item)}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}>
                            <span>
                              {convertToUTC(item?.next_software_update_due) ||
                                "NA"}
                            </span>
                            {isDateAfterToday(
                              item?.next_software_update_due
                            ) ? (
                              <img
                                src={warningUpdatesoftware}
                                alt="logo"
                                width={20}
                                height={20}
                              />
                            ) : (
                              <img
                                src={softwareUpdatedIcon}
                                alt="logo"
                                width={20}
                                height={20}
                              />
                            )}
                          </div>
                        </td>
                        <td
                          className="td-default-device"
                          onClick={() => handleNameClick(item)}>
                          {item?.name || "NA"}
                        </td>
                        <td className="td-default-device">
                          ${item?.tapAmount / 100 || 0}
                        </td>
                        <td>
                          <div
                            className={`device-status ${
                              item?.status === "ACTIVE" ? "active" : "inactive"
                            }`}
                            onClick={() =>
                              handleStatusChange(
                                item?.reader_id,
                                item?.status === "ACTIVE"
                              )
                            }>
                            {item?.status === "ACTIVE" ? "Active" : "Inactive"}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="8"
                        style={{ textAlign: "center", padding: "20px" }}>
                        Data Not Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="pagination mb-4">
              <Pagination 
                 currentPage={currentPage}
                 totalPages={totalPages}
                 onPrevious={handlePreviousPage}
                 onNext={handleNextPage}
              />  
            </div>
          </div>
          <div>
            <Footer />
          </div>
        </div>
      </div>
      {/* Popup Dialog */}
      <PopupDialog
        open={isPopupOpen}
        onClose={handleCloseDialog}
        title="Onboard New Staff">
        <AddUserForm
          accessToken={accessToken}
          community_id={community_id}
          cancelHandler={handleCloseDialog}
        />
      </PopupDialog>

      {/* Reader Order Popup */}
      <PopupDialog
        open={isReaderPopupOpen} // Ensure this is controlled by state
        onClose={handleCloseReaderDialog}
        title="Order Requite Payment Readers"
        className="popup-dialog"
        style={{ overflowX: "hidden" }}>
        <div className="iframe-container">
          <iframe
            src={iframeSrc}
            title="Order Requite Payment Readers"
            frameBorder="0"
            style={{ overflowX: "hidden" }}></iframe>
        </div>
      </PopupDialog>
    </>
  );
};

export default ManageDevice;
