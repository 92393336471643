import { createSlice } from "@reduxjs/toolkit";
import { communityBankAccountAddAction } from "../../api/api";

const initialState = {
  communityBankAccountData: null,
  loading: false,
  error: null,
};

const CommunityBankAccountSlice = createSlice({
  name: "communityBankAccount",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(communityBankAccountAddAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(communityBankAccountAddAction.fulfilled, (state, action) => {
        state.loading = false;
        state.communityBankAccountData = action.payload; 
      })
      .addCase(communityBankAccountAddAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; 
      });
  },
});

export default CommunityBankAccountSlice.reducer;
