import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import "./sidebar.css";
import TipNTapLogo from "../../assets/images/TapNtipLogo.png";
import profileImage from "../../assets/images/user-profile-pic.png";
import helpSupportImage from "../../assets/images/Question-Mark-Circle.png";
import vector from "../../assets/images/vector.png";
import home from "../../assets/images/home.png";
import staff from "../../assets/images/staff.png";
import device from "../../assets/images/device.png";
import settings from "../../assets/images/settings.png";
import signoutIcon from "../../assets/images/signoutIcon.png";
import payouticon from "../../assets/images/payouticon.png";

const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState();
  const { data } = useSelector((state) => state.memberInfoStore);

  const communityLogos = useMemo(() => {
    const community_single_Logo = localStorage.getItem("communityLogo");
    return community_single_Logo && community_single_Logo !== "null"
      ? community_single_Logo
      : TipNTapLogo;
  }, []);

  const companyLogoBottom = useMemo(() => {
    const storedLogo = localStorage.getItem("communityLogo");
    return storedLogo && storedLogo !== "null" ? TipNTapLogo : null;
  }, []);

  const profileMenuRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, [location.pathname]); 

  const handleProfileClick = () => {
    setShowProfileMenu(!showProfileMenu);
  };

  const handleViewProfile = () => {
    navigate("/my-profile");
    setShowProfileMenu(false);
  };

  const handleSignOut = () => {
    localStorage.removeItem("selectedCommunity");
    localStorage.removeItem("accesstoken");
    localStorage.removeItem("manage_by");
    localStorage.removeItem("refreshToken");
    navigate("/");
    window.location.reload();
    setShowProfileMenu(false);
  };

  const handleItemClick = (item) => {
    switch (item) {
      case "Home":
        navigate("/dashboard");
        break;
      case "managestaff":
        navigate("/manage-staff");
        break;
      case "managedevice":
        navigate("/manage-device");
        break;
      case "settings":
        navigate("/settings");
        break;
      case "managepayout":
        navigate("/manage-payout");
        break;
      default:
        break;
    }
    setActiveMenu(item);
  };

  const isActive = (path) => location.pathname === path;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target)
      ) {
        setShowProfileMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
      <div className="sidebar-header">
        <div className="logo-container">
          <div className="logo-subcontainer">
            <img src={communityLogos} alt="logo" className="topLogo" />
          </div>
          <div>
            {!isCollapsed && (
              <div className="tagline">
                Effortless Tipping Management for Your Business
              </div>
            )}
          </div>
        </div>
      </div>
      <ul className="sidebar-menu">
        <li
          className={isActive("/dashboard") ? "active" : ""}
          onClick={() => handleItemClick("Home")}
        >
          <img src={home} alt="Home Icon" className="menu-icon" />
          {!isCollapsed && "Home"}
        </li>
        <li
          className={isActive("/manage-staff") ? "active" : ""}
          onClick={() => handleItemClick("managestaff")}
        >
          <img src={staff} alt="Staff Icon" className="menu-icon" />
          {!isCollapsed && "Staff"}
        </li>
        <li
          className={isActive("/manage-device") ? "active" : ""}
          onClick={() => handleItemClick("managedevice")}
        >
          <img src={device} alt="Device Icon" className="menu-icon" />
          {!isCollapsed && "Device"}
        </li>
        <li
          className={isActive("/manage-payout") ? "active" : ""}
          onClick={() => handleItemClick("managepayout")}
        >
          <img src={payouticon} alt="Payout Icon" className="menu-icon" />
          {!isCollapsed && "Payout"}
        </li>
        <li className={isActive("/settings") ? "active" : ""} onClick={() => handleItemClick("settings")}>
          <img src={settings} alt="Settings Icon" className="menu-icon" />
          {!isCollapsed && "Settings"}
        </li>
      </ul>
      <>
        {companyLogoBottom && (
          <div
            style={{
              width: "203px",
              maxHeight: "57px",
              position: "fixed",
              bottom: "106px",
              left: "15px",
            }}
          >
            <img
              src={companyLogoBottom}
              alt="Company Logo Bottom"
              style={{ width: "150px", height: "38px", opacity: "0.7" }}
            />
          </div>
        )}
        <div className="sidebar-footer">
          <div className="sidbarfooter">
            <div style={{ position: "relative", top: "-20px" }}>
              <img
                src={helpSupportImage}
                alt="Help Support"
                className="help-support-image"
                onClick={() => navigate("/help-and-support")}
              />
              <span
                className="helpSupportText"
                onClick={() => navigate("/help-and-support")}
              >
                Help & Support
              </span>
            </div>
          </div>

          <img src={vector} alt="divider" className="divider" />
          <div className="user-info">
            <div className="profile-container" onClick={handleProfileClick}>
              <img
                src={data?.profile_url || profileImage}
                alt="profile"
                className="profile-pic"
                onClick={handleViewProfile}
              />
            </div>
            <div className="user-details">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  color: "#fff",
                }}
              >
                {data?.name || "User"}
              </div>
              <div className="user-lastLogin">
                Last Login: {data?.last_logged_in_ts || " 2 Days Ago"}
              </div>
            </div>
            <div className="sinout-bar">
              <img
                src={signoutIcon}
                alt="signoutIcon"
                className="signoutIcon"
                onClick={handleSignOut}
              />
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default SideBar;
