// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RightSideContent_logoContainer__9nIJV {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.RightSideContent_logotapntip__rVvaj {
  width: 100%;
  display: flex;
  justify-content: center;
}
.RightSideContent_logoText__nU4Jj {
  width: 100%;
  padding-top: 15px;
  display: block;
  text-align: left;
  font-size: var(--font-size-heading-18);
  font-family: var(--font-Family);
  font-weight: var(--font-weight-600);
  color:var(--primary-color);
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1024px) {
  .RightSideContent_logoContainer__9nIJV {
    height: 12.5vh;
    position: relative;
    top: 5px;
    margin-left: 0px;
  }
  .RightSideContent_logotapntip__rVvaj {
    width: 100%;
    text-align: center;
  }
  .RightSideContent_logoText__nU4Jj {
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .RightSideContent_logoContainer__9nIJV {
    height: 15vh;
    position: relative;
    top: 5px;
    margin-left: 0px;
  }
  .RightSideContent_logoContainer__9nIJV {
    margin-left: 0px;
    margin-right: 0px;
    text-align: center;
  }
  .RightSideContent_logoText__nU4Jj {
    text-align: center;
  }
  .RightSideContent_logotapntip__rVvaj {
    width: 100%;
    text-align: center;
  }
  .RightSideContent_logoText__nU4Jj {
    text-align: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/RightSideContent/RightSideContent.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,WAAW;EACX,iBAAiB;EACjB,cAAc;EACd,gBAAgB;EAChB,sCAAsC;EACtC,+BAA+B;EAC/B,mCAAmC;EACnC,0BAA0B;EAC1B,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE;IACE,cAAc;IACd,kBAAkB;IAClB,QAAQ;IACR,gBAAgB;EAClB;EACA;IACE,WAAW;IACX,kBAAkB;EACpB;EACA;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,YAAY;IACZ,kBAAkB;IAClB,QAAQ;IACR,gBAAgB;EAClB;EACA;IACE,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;EACpB;EACA;IACE,kBAAkB;EACpB;EACA;IACE,WAAW;IACX,kBAAkB;EACpB;EACA;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".logoContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin-top: 50px;\n}\n.logotapntip {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n.logoText {\n  width: 100%;\n  padding-top: 15px;\n  display: block;\n  text-align: left;\n  font-size: var(--font-size-heading-18);\n  font-family: var(--font-Family);\n  font-weight: var(--font-weight-600);\n  color:var(--primary-color);\n  display: flex;\n  justify-content: center;\n}\n\n@media only screen and (max-width: 1024px) {\n  .logoContainer {\n    height: 12.5vh;\n    position: relative;\n    top: 5px;\n    margin-left: 0px;\n  }\n  .logotapntip {\n    width: 100%;\n    text-align: center;\n  }\n  .logoText {\n    text-align: center;\n  }\n}\n\n@media only screen and (max-width: 768px) {\n  .logoContainer {\n    height: 15vh;\n    position: relative;\n    top: 5px;\n    margin-left: 0px;\n  }\n  .logoContainer {\n    margin-left: 0px;\n    margin-right: 0px;\n    text-align: center;\n  }\n  .logoText {\n    text-align: center;\n  }\n  .logotapntip {\n    width: 100%;\n    text-align: center;\n  }\n  .logoText {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoContainer": `RightSideContent_logoContainer__9nIJV`,
	"logotapntip": `RightSideContent_logotapntip__rVvaj`,
	"logoText": `RightSideContent_logoText__nU4Jj`
};
export default ___CSS_LOADER_EXPORT___;
