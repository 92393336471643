import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "./devicedetail.css";
import SideBar from "../../../components/SideBar/SideBar";
import SelectCommunity from "../../../components/SelectCommunity/SelectCommunity";
import {ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";  
import "../../../App.css"
import {  ActiveInactiveDeviceAction, getCommunityReaderAction, getReaderHistoryAction, UpdateReaderAction} from "../../../redux/api/api";
import userinvite from "../../../assets/images/userinvite.png";
import Footer from "../../../components/Footer/Footer";
import upicon from "../../../assets/images/Up.png"
import PopupDialog from "../../../components/Popup/PopupDialog";
import AddUserForm from "../../../components/UserForm/AddUserForm";
import { convertToUTCDate, convertToUTCUSA } from "../../../Utility/Utility";

const DeviceDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [initialStaff, setInitialStaff] = useState(state?.item || {}); 
  const [errors, setErrors] = useState({});
  const { deviceList } = useSelector((state) => state.GetCommunityReaderStore);
  const [deviceData, setDeviceData] = useState();
  const [formData, setFormData] = useState({
    reader_friendly_name: initialStaff?.reader_friendly_name || "",
    tapAmount:initialStaff?.tapAmount || "",
    status:initialStaff?.status
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [accessTokens, setAccessTokens] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const accessToken = localStorage.getItem("accesstoken");
  const community_id = localStorage.getItem("selectedCommunity");
  const reader_id = initialStaff?.reader_id
  const { readerHistory } = useSelector((state) => state.ReaderHistoryStore);
  
  useEffect(() => {
    if (accessToken) {
      setAccessTokens(accessToken);
    }
  }, [accessToken]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "tapAmount") {
    if (value > 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        tapAmount: "Tip Amount cannot be greater than 10.",
      }));
    } else {
      setErrors((prevErrors) => {
        const { tapAmount, ...otherErrors } = prevErrors;
        return otherErrors; 
      });
    }
  }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '', 
    }));
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setFormData({
      reader_friendly_name: initialStaff?.reader_friendly_name || "",
      tapAmount:initialStaff?.tapAmount / 100 || "",
      status:initialStaff?.status
    });
    setIsEditing(false);
    setErrors({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
  const sanitizedData = {
    reader_friendly_name: (formData?.reader_friendly_name || 'NA').toString().trim(),
    tapAmount: (formData?.tapAmount || 0).toString().trim(),
  };

  const validationErrors = {};
  if (!sanitizedData?.reader_friendly_name) {
    validationErrors.reader_friendly_name = 'Reader Name is required';
  }

  if (Object.keys(validationErrors).length > 0) {
    setErrors(validationErrors);
    return;
  }

    const accessToken = accessTokens;
    const reader_id = initialStaff?.reader_id;
    const payload = {
        reader_friendly_name: formData?.reader_friendly_name,
    };

    try {
        const result = await dispatch(UpdateReaderAction({ accessToken, reader_id, community_id, payload }));
        if (UpdateReaderAction.fulfilled.match(result)) {
            setInitialStaff((prevStaff) => ({
                ...prevStaff,
                reader_friendly_name: formData?.reader_friendly_name,
            }));
            toast.success("Successfully Updated");
            const res = await dispatch(getCommunityReaderAction({ accessToken, community_id }));
            if (getCommunityReaderAction.fulfilled.match(res)) {
            }
        }
        setIsEditing(false);
        setErrorMessage("");
        setErrors({});
    } catch (error) {
        toast.error("Failed to update.");
        setErrorMessage("Failed to update.");
    }
};


useEffect(() => {
  if (deviceList && deviceList?.length > 0) {
    const updatedDevice = deviceList?.find(device => device?.reader_id === initialStaff?.reader_id);
    const updateAmount = deviceList?.find(item => item?.tapAmount === initialStaff?.tapAmount);
    const updatedStatus = deviceList?.find(item => item?.status === initialStaff?.status)
    if (updatedDevice) {
      setInitialStaff(updatedDevice);
      setFormData({
        reader_friendly_name: updatedDevice?.reader_friendly_name || "",
        tapAmount: updateAmount?.tapAmount / 100,
        status:updatedStatus?.status
      });
    }
  }
}, [deviceList, initialStaff, reader_id]);

  useEffect(() => {
    if (accessToken && community_id) {
      dispatch(getCommunityReaderAction({ accessToken, community_id }));
    }
  }, [dispatch, accessToken, community_id]);

  useEffect(() => {
    if (deviceList) {
      setDeviceData(deviceList);
    }
  }, [deviceList]);

  const backButton = () => {
    navigate("/manage-device");
    localStorage.removeItem("ReaderName");
  };

  const handleStatusChange = async (newStatus) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      status: newStatus,
    }));
    try {
      await dispatch(ActiveInactiveDeviceAction({
        accessToken,
        community_id,
        reader_id,
        status: newStatus,
      })).unwrap();
    } catch (error) {
      toast.error(error.detail[0]?.msg);
      setFormData((prevFormData) => ({
        ...prevFormData,
        status: newStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE",
      }));
    }
  };

  useEffect(() => {
    dispatch(getReaderHistoryAction({ accessToken, community_id, reader_id}));
  }, [dispatch, accessToken, community_id, reader_id]);

  const handleOpenDialog = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const handleCloseDialog = () => {
    setIsPopupOpen(false);
  };

  const handleHomeClick = () => {
    navigate("/dashboard");
  };

  return (
   
    <>
     {deviceList ?
      <div className="DeviceDetailMainPage mainLayoutPage">
      <ToastContainer /> 
      <div className="sidebar">
          <SideBar />
        </div>
        <div className="dashboard-container borderFormat">
        <div className="header mb-4">
        <div className="titleheading-content">Device
              <div className="subtitle">
                  <div className="home-text" onClick={handleHomeClick}>
                    <img src={upicon} alt="logo"/> Home /</div>
                    <div className="home-text" onClick={backButton}>Device /</div>
                  <div className="current-page-text">{initialStaff?.reader_friendly_name}</div>
              </div>
         </div>
            <div className="dropdownMain mainDropdownTablet">
              <div className="mediumScreenHeader">
                <div className="header-button" > <SelectCommunity /></div>
                <button className="onboard-button" onClick={handleOpenDialog}>
                  <span>Onboard New Staff</span>
                  <img src={userinvite} alt="userInvite"></img>
                </button>
              </div>
            </div>
          </div>
          <div className="mainUserDetailsDevice">
            <div className="userDetailHeading">
              <div className="subHeading">
                <div>Reader Info</div>
                {isEditing ? (
                  <div className="saveCancel">
                  <button onClick={handleSubmit} className="editBtnUser saveBtnAlign"  style={{position:'relative',right:'20px'}}>Save</button>
                  <button onClick={handleCancelEdit} class="btn btn-secondary">Cancel</button>
                  </div>
                ) : (
                  <button onClick={handleEditClick} className="editBtnUser">Edit</button>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-lg-2"><img src="https://s3.us-east-1.amazonaws.com/requite.xyz/public_asset/stripe_m2.png" alt="logo" style={{width:'180px',height:'108px'}} /></div>
              <div className="col-lg-4">
              <div className="deviceDetailTextReadOnly">
                  <div className="deviceText">Make/ Model:  </div>
                  <div className="deviceTextValue">{initialStaff?.model || "NA"}</div>
                </div>
                <div className="deviceDetailTextReadOnly">
                 <div className="deviceText">Serial Number:</div>
                 <div className="deviceTextValue">{initialStaff?.serial_number || "NA"}</div>
                </div>
                <div className="deviceDetailTextReadOnly">
                  <div className="deviceText">Firmware Version:</div>
                  <div className="deviceTextValue">{initialStaff?.software_version || "NA"}</div>
                </div>
              </div>
              <div className="col-lg-6 customLabelBox">
              <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group pt-2" style={{display:"flex"}}>
                        <label htmlFor="reader_friendly_name" className="deviceDetailTextSubPara" style={{width:"200px", marginTop:"15px"}}>Custom Label:</label>
                        <input
                          type="text"
                          className="form-control customLabelBox"
                          name="reader_friendly_name"
                          value={formData?.reader_friendly_name}
                          placeholder="Enter Reader Name"
                          onChange={handleChange}
                          disabled={!isEditing} 
                          style={{fontSize:'14px', height:'40px', width:'200px'  }}
                        />
                      
                      </div>
                      <div style={{display:'flex',justifyContent:'flex-end'}}>
                        {errors?.reader_friendly_name && (
                          <div className="error-message-device">
                            {errors?.reader_friendly_name}
                          </div>
                        )}
                        </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group pt-2" style={{display:"flex"}}>
                        <label htmlFor="reader_friendly_name" className="deviceDetailTextSubPara" style={{width:"200px", marginTop:"15px"}}>Fixed Tip Amount ($):</label>
                        <input
                          type="text"
                          className="form-control customLabelBox"
                          name="tapAmount"
                          value={formData?.tapAmount || ''}
                          placeholder="Fixed Tip Amount set for this device"
                          onChange={handleChange}
                          disabled
                          style={{fontSize:'14px', height:'40px', width:'100px'}}
                        />
                      </div>
                      <div style={{display:'flex',justifyContent:'flex-end'}}>
                          {errors?.tapAmount && (
                            <div className="error-message-device">
                              {errors?.tapAmount}
                            </div>
                          )}
                        </div>
                    </div>

                  <div className="col-md-12 customLabelBox" >
                    <div className="form-group pt-3" style={{display:"flex"}}>
                       <label htmlFor="status" className="deviceDetailTextSubPara" style={{width:"200px"}}>Status:</label>
                         <div className="form-check" style={{ paddingRight: "15px" }}>
                         <label className="form-check-label" htmlFor="flexCheckDefault" style={{ width:'100px',marginLeft:'10px',marginTop:'1px'}}>
                            In Use Active
                          </label>
                          <input class="form-check-input" type="radio"
                            name="status" id="exampleRadios1"
                            disabled={!isEditing} 
                            checked={formData?.status === "ACTIVE"}   onChange={() => handleStatusChange("ACTIVE")} 
                          />
                        </div>
                        <div className="form-check pl-2">
                        <label className="form-check-label" htmlFor="flexCheckChecked" style={{ marginLeft:'10px',marginTop:'1px'}}>
                            Disable (not in use)
                          </label>
                              <input class="form-check-input" type="radio" name="status"
                          id="exampleRadios2"  disabled={!isEditing} checked={formData?.status === "INACTIVE"}     onChange={() => handleStatusChange("INACTIVE")} 
                       />
                        </div>
                      </div>
                    </div> 
                  </div>
                </form>
               </div>
              <div>
             </div>
            </div>
          </div>

          <div className="recentTransactionHistory mt-3">
          <div className="deviceRecentTransaction">
            <div className="leaderboard-header">
              <div className="headerDeviceDetail" style={{ paddingTop: '2px' }}>Recent Transactions</div>
            </div>
            <ul className="activity-list" style={{ paddingTop: '15px' }}>
              {readerHistory?.performed_last_5_tips?.length > 0 ? (
                readerHistory?.performed_last_5_tips?.map((item, index) => (
                  <li key={index} className="activity-item" style={{ marginBottom: '5px', paddingTop: '5px', paddingBottom: '5px' }}>
                    <div className="card-icon" style={{ position: 'relative', right: '12px' }}>
                      <span style={{ fontWeight: '600' }}>
                      {item.payment_read_method === "QRCode" ? (
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="30px"
                                  viewBox="0 -960 960 960"
                                  width="30px"
                                  fill="#5f6368">
                                  <path d="M520-120v-80h80v80h-80Zm-80-80v-200h80v200h-80Zm320-120v-160h80v160h-80Zm-80-160v-80h80v80h-80Zm-480 80v-80h80v80h-80Zm-80-80v-80h80v80h-80Zm360-280v-80h80v80h-80ZM173.33-653.33h133.34v-133.34H173.33v133.34ZM120-600v-240h240v240H120Zm53.33 426.67h133.34v-133.34H173.33v133.34ZM120-120v-240h240v240H120Zm533.33-533.33h133.34v-133.34H653.33v133.34ZM600-600v-240h240v240H600Zm80 480v-120h-80v-80h160v120h80v80H680ZM520-400v-80h160v80H520Zm-160 0v-80h-80v-80h240v80h-80v80h-80Zm40-200v-160h80v80h80v80H400Zm-190-90v-60h60v60h-60Zm0 480v-60h60v60h-60Zm480-480v-60h60v60h-60Z" />
                                </svg>
                              </div>
                            ) : (
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="30px"
                                  viewBox="0 -960 960 960"
                                  width="30px"
                                  fill="#5f6368">
                                  <path d="M880-733.33v506.66q0 27-19.83 46.84Q840.33-160 813.33-160H146.67q-27 0-46.84-19.83Q80-199.67 80-226.67v-506.66q0-27 19.83-46.84Q119.67-800 146.67-800h666.66q27 0 46.84 19.83Q880-760.33 880-733.33ZM146.67-634h666.66v-99.33H146.67V-634Zm0 139.33v268h666.66v-268H146.67Zm0 268v-506.66 506.66Z" />
                                </svg>
                              </div>
                            )}
                      </span>
                    </div>
                    <div className="deviceTransactionDetail">
                      <div className="transaction-time" style={{ width: '20%' }}>{item?.name || "NA"}</div>
                      <div className="transaction-time">{convertToUTCUSA(item?.transaction_ts) || "NA"}</div>
                      <div className="transaction-amount">${item?.amount / 100 || "NA"}</div>
                    </div>
                  </li>
                ))
              ) : (
                <li className="activity-item" style={{ textAlign: 'center', padding: '10px', marginTop:'7px' }}>
                  Data Not Found
                </li>
              )}
            </ul>
          </div>
              <div className="deviceRecentHistoryLastFive">
                <div className="headerDeviceDetail" style={{padding:'8px 0px 15px 10px'}}>Usages History:</div>
                  <div className="table-responsive">
                      <table className="table --table-bordered">
                      <thead className="table-header">
                          <tr>
                            <th>Tip Id</th>
                            <th>Name</th>
                            <th>Date Time</th>
                          </tr>
                        </thead>
                        <tbody>
                        {readerHistory?.who_last_used?.length > 0 ? (
                            readerHistory?.who_last_used?.map((item) => (
                              <tr key={item?.tip_id}>
                                <td className="textDecorationTableTd">{item?.tip_id.split('-').pop() || "NA"}</td>
                                <td className="textDecorationTableTd">{item?.name || "NA"}</td>
                                <td className="textDecorationTableTd">{convertToUTCDate(item?.transaction_ts) || "NA"}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="3" style={{ textAlign: 'center' }}>
                                Data Not Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                 </div>
              </div>
          </div>
          <div className="mt-4">
          <Footer />
          </div>
        </div>
          <PopupDialog open={isPopupOpen} onClose={handleCloseDialog} title="Onboard New Staff">
          <AddUserForm accessToken={accessToken} community_id={community_id} cancelHandler={handleCloseDialog} />
        </PopupDialog>
      </div>
      : <div>Lodding..</div>}
    </> 
  );
};

export default DeviceDetail;
