// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/drop-icon-1.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .selectedCom .form-select
   {
    --bs-form-select-bg-img: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;
    background-color: var(--drop-down-bg-color) !important;
    color: var(--primary-color) !important;
   }

   .selectedCom .form-select:focus{
    border-color: transparent !important;
    outline: 0;
    box-shadow: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/SelectCommunity/selectcommunity.css"],"names":[],"mappings":"EAAE;;IAEE,2EAA4E;IAC5E,sDAAsD;IACtD,sCAAsC;GACvC;;GAEA;IACC,oCAAoC;IACpC,UAAU;IACV,2BAA2B;AAC/B","sourcesContent":["  .selectedCom .form-select\n   {\n    --bs-form-select-bg-img: url(../../assets/images/drop-icon-1.png) !important;\n    background-color: var(--drop-down-bg-color) !important;\n    color: var(--primary-color) !important;\n   }\n\n   .selectedCom .form-select:focus{\n    border-color: transparent !important;\n    outline: 0;\n    box-shadow: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
