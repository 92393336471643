import { createSlice } from '@reduxjs/toolkit';
import { tipShareReceiptAction } from '../../api/api';

const initialState = {
  shareTipUrl: null,
  loading: false,
  error: null,
};

const TipShareReceiptSlice = createSlice({
  name: 'tipShareReceipt',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(tipShareReceiptAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(tipShareReceiptAction.fulfilled, (state, action) => {
        state.loading = false;
        state.shareTipUrl = action.payload['share-tip-url']; // Assuming response has this property
      })
      .addCase(tipShareReceiptAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default TipShareReceiptSlice.reducer;
