import React from "react";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import "./PopupDialog.css";

const PopupDialog = ({
  open,
  onClose,
  title,
  children,
  onSubmit,
  submitText = "Submit",
  closeText = "Close",
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: "custom-dialog" }}
      sx={{
        "& .MuiDialog-paper": {
          position: "fixed",
          top: "0px",
          right: "0px",
          margin: 0,
          width: "500px",
          height: "100%",
          maxHeight: "100%",
          borderRadius: "8px",
          transition: "left 0.5s ease-in-out",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
          right: open ? "0" : "-2500px",
        },
      }}
    >
      <DialogTitle className="custom-dialog-title">
        {title}

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: -2,
            backgroundColor: "transparent", 
            '&:hover': {
              backgroundColor: "transparent", 
            },
            '&:active': {
              backgroundColor: "transparent", 
            },
            '&:focus': {
              backgroundColor: "transparent", 
            }
          }}>
          X
        </IconButton>

      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
};

export default PopupDialog;
