import React, { useEffect, useRef, useState } from "react";
import SideBar from "../../components/SideBar/SideBar";
import Header from "../../components/Header/Header";
import SelectCommunity from "../../components/SelectCommunity/SelectCommunity";
import './settings.css'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../App.css"
import { useDispatch, useSelector } from "react-redux";
import { getSettingAction, updateTipAmountSettingCommunityMember, updatePayoutConfigSettingCommunityMember, communityLogoAction, communityAssetBgAction, communityGenerateQRCodeAction, getAddressBranch } from "../../redux/api/api";
import Footer from "../../components/Footer/Footer";
import userinvite from "../../assets/images/userinvite.png";
import upicon from "../../assets/images/Up.png"
import { useNavigate } from "react-router-dom";
import AddUserForm from "../../components/UserForm/AddUserForm";
import PopupDialog from "../../components/Popup/PopupDialog";
import AddressDetail from "../../components/AddressDetail/AddressDetail";

const Settings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isEditingTip, setIsEditingTip] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [data, setData] = useState({});
  const { settingData } = useSelector((state) => state.SettingStore);
  const { addressData } = useSelector((state) => state.GetAddressDetailStore);
  const [localAddressData, setLocalAddressData] = useState([]);

  const [isModalOpen, setModalOpen] = useState(false);
  const accessToken = localStorage.getItem("accesstoken");
  const community_id = localStorage.getItem("selectedCommunity");
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedBgFile, setSelectedBgFile] = useState(null);
  const fileInputBgRef = useRef(null);
  const [tipModes, setTipModes] = useState({
    tip_amount: "",
    receipt_message:"",
    tax_id:"",
    expected_number_of_tip:"",
    qr_tip_amount: null,
    pr_tip_amount: null,
    mr_tip_amount: null,
  });

  const [bankDetailData, setBankDetailData] = useState(null)

  const [payoutConfigModes, setPayoutConfigModes] = useState({
    frequency: "",
    managed_by: "",
    distribution_rule: "",
  });

  useEffect(() => {
    if (accessToken && community_id) {
      dispatch(getSettingAction({ accessToken, community_id }));
    }
  }, [accessToken, community_id, dispatch]);




  useEffect(() => {
    if (accessToken && community_id) {
      dispatch(getAddressBranch({ accessToken, community_id }));
    }
  }, [accessToken, community_id, dispatch]);

  useEffect(() => {
    if (addressData) {
      setLocalAddressData(addressData);
    }
  }, [addressData]);

  useEffect(() => {
    if (settingData) {
      setData(settingData);
      setTipModes({
        tip_amount: settingData?.tip_amount / 100,
        receipt_message:settingData?.receipt_message,
        tax_id:settingData?.tax_id,
        expected_number_of_tip:settingData?.expected_number_of_tip,
        qr_tip_amount: settingData?.qr_tip_amount != null ? true : false,
        pr_tip_amount: settingData?.pr_tip_amount != null ? true : false,
        mr_tip_amount: settingData?.mr_tip_amount != null ? true : false
      });
      setPayoutConfigModes({
        frequency: settingData?.payout_config ? settingData?.payout_config?.frequency : "",
        managed_by: settingData?.payout_config ? settingData?.payout_config?.managed_by : "",
        distribution_rule: settingData?.payout_config ? settingData?.payout_config?.distribution_rule : "",
      });
    }
  }, [settingData]);

  const handleTipModeChange = (event) => {
    const { name, checked } = event.target;
    setTipModes((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleEditClick = () => {
    setIsEditingTip(true);
    setTipModes(prev => ({
      ...prev,
      qr_tip_amount: prev?.qr_tip_amount,
      pr_tip_amount: prev?.pr_tip_amount,
      mr_tip_amount: prev?.mr_tip_amount
    }));
  };

  const handleCancelEdit = () => {
    setTipModes((prev) => ({
      ...prev,
      tip_amount: prev?.tip_amount,
      receipt_message:prev?.receipt_message,
      tax_id:prev?.tax_id,
      expected_number_of_tip:prev?.expected_number_of_tip,
      qr_tip_amount: prev?.qr_tip_amount,
      pr_tip_amount: prev?.pr_tip_amount,
      mr_tip_amount: prev?.mr_tip_amount,
    }));
    setIsEditingTip(false)
  };

  const handleFixedTipAmountChange = (event) => {
    const { value } = event.target;
    if (!isNaN(value) || value === "") {
      setTipModes((prev) => ({
        ...prev,
        tip_amount: value,
      }));
    }
  };

  const handleFixedReceiptMessageChange = (event) => {
    const { value } = event.target;
    setTipModes((prev) => ({
      ...prev,
      receipt_message: value,
    }));
  };

  const handletax_idChange = (event) => {
    const { value } = event.target;
    setTipModes((prev) => ({
      ...prev,
      tax_id: value,
    }));
  };
  
  const handleExpectedNumberOfTipChange = (event) => {
    const { value } = event.target;
    setTipModes((prev) => ({
      ...prev,
      expected_number_of_tip: value,
    }));
  };
  


  const handleSaveUpdate = async (e) => {
    e.preventDefault();
    if (isNaN(tipModes?.tip_amount) || tipModes?.tip_amount <= 0) {
      toast.error("Please enter a valid tip amount.");
      return;
    }
    const payload = {
      tip_amount: Math.round(tipModes?.tip_amount * 100),
      receipt_message:tipModes?.receipt_message,
      tax_id:tipModes?.tax_id,
      expected_number_of_tip:tipModes?.expected_number_of_tip,
      qr_tip_amount: tipModes?.qr_tip_amount === true ? Math.round(tipModes?.tip_amount * 100) : null,
      pr_tip_amount: tipModes?.pr_tip_amount === true ? Math.round(tipModes?.tip_amount * 100) : null,
      mr_tip_amount: tipModes?.mr_tip_amount === true ? Math.round(tipModes?.tip_amount * 100) : null,
    };
    try {
      setIsEditingTip(false);
      const response = await dispatch(
        updateTipAmountSettingCommunityMember({ accessToken, community_id, payload })
      );
      if (updateTipAmountSettingCommunityMember.fulfilled.match(response)) {
        toast.success("Tip Configuration updated successfully!");
      }
      else {
        toast.error(response?.payload?.detail || "Failed to update the Tip Configuration.");
        setErrorMessage(response?.payload?.detail || "Failed to update the Tip Configuration.");
      }
    }
    catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.detail || "An error occurred.");
      }
      else {
        toast.error("Failed to update the Tip Configuration. Please try again later.");
      }
      setErrorMessage("Failed to update the Tip Configuration.");
    }
    setIsEditingTip(false);
  };

  const handleEditClickConfigure = () => setIsEditing(true);

  const handleCancelEditConfigure = () => {
    setPayoutConfigModes((prev) => ({
      ...prev,
      frequency: prev?.frequency,
      managed_by: prev?.managed_by,
      distribution_rule: prev?.distribution_rule
    }));
    setIsEditing(false);
  }

  const handleSubmitConfigure = async (e) => {
    e.preventDefault();
    try {
      setIsEditing(false);
      const payload = {
        frequency: payoutConfigModes?.frequency,
        managed_by: payoutConfigModes?.managed_by,
        distribution_rule: payoutConfigModes?.distribution_rule
      };
      const response = await dispatch(
        updatePayoutConfigSettingCommunityMember({ accessToken, community_id, payload })
      );
      if (updatePayoutConfigSettingCommunityMember.fulfilled.match(response)) {
        toast.success("Payout Configuration updated successfully!");
      }
      else {
        toast.error(response.payload?.detail || "Failed to update the Payout Configuration.");
        setErrorMessage(response.payload?.detail || "Failed to update the Payout Configuration.");
      }
      dispatch(getSettingAction({ accessToken, community_id }));
    } catch (error) {
      if (error?.response && error?.response?.data) {
        toast.error(error.response.data?.detail || "An error occurred.");
      } else {
        toast.error("Failed to update the Payout Configuration. Please try again later.");
      }
      setErrorMessage("Failed to update the Payout Config.");
    }
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setPayoutConfigModes((prevModes) => ({
      ...prevModes,
      [name]: value,
    }));
  };

  const handleSubmitPayoutConfig = () => { }

  const handleChange = () => { }

  const handleOpenDialog = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const handleCloseDialog = () => {
    setIsPopupOpen(false);
  };

  const handleHomeClick = () => {
    navigate("/dashboard");
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUploadCommunityLogo = async () => {
    if (!selectedFile) {
      toast('Please select a file first!');
      return;
    }

    const payload = new FormData();
    payload.append('file', selectedFile);
    try {
      const result = await dispatch(communityLogoAction({ accessToken, community_id, payload })).unwrap();
      toast("File uploaded successfully!");

      setSelectedFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (error) {
      toast(error?.detail);
    }
  };

  const handleFileBgImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedBgFile(file);
  };

  const handleUploadCommunityBgImage = async () => {
    if (!selectedBgFile) {
      toast('Please select a file first!');
      return;
    }
    const payload = new FormData();
    payload.append('file', selectedBgFile);
    try {
      const result = await dispatch(communityAssetBgAction({ accessToken, community_id, payload })).unwrap();
      toast("File uploaded successfully!");
      setSelectedBgFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (error) {
      toast("Failed to upload file.");
    }

  };

  const generateNewQRCodeCommunity = async () => {
    try {
      const result = await dispatch(communityGenerateQRCodeAction({ accessToken, community_id })).unwrap();
      toast("QR code generated successfully!");
      window.location.reload()
    } catch (error) {
      toast("Failed to QR code generated");
    }
  };

  const addBankCommunity = () => {
    navigate('/add-community-bank-account')
  }
  const handleDelete = () => {
    toast.info("This functionality is currently in development.");
  };

  return (
    <>
      <div className="settingMainPage mainLayoutPage">
        <ToastContainer />
        <SideBar />
        <div className="dashboard-container borderFormat">
          <div className="header">
            <div >
              <div className="titleheading-content">Setting
                <div className="subtitle">
                  <div className="home-text" onClick={handleHomeClick}>
                    <img src={upicon} alt="UpIcon" /> Home /</div>
                  <div className="current-page-text">Setting</div>
                </div>
              </div>
              <div className="tabletScreenHeader">
                <Header />
              </div>
            </div>
            <div className="dropdownMain mainDropdownTablet">
              <div className="mediumScreenHeader">
                <div className="header-button" > <SelectCommunity /></div>
                <button className="onboard-button" onClick={handleOpenDialog}>
                  <span>Onboard New Staff</span>
                  <img src={userinvite} alt="UserIcon" />
                </button>
              </div>
            </div>
          </div>
          <div className="top-line"></div>
          <div class="containerSettingSection">
            <div class="sectionFirstSetting">
              <div className="tipConfiguration">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div className="headerCommonSetting"><h5 className="settingHeading">Tip Configuration</h5></div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {isEditingTip ? (
                      <div className="saveCancel">
                        <button
                          onClick={handleSaveUpdate}
                          className="editBtnUser saveBtnAlign">
                          Save
                        </button>
                        <button onClick={handleCancelEdit} className="btn btn-secondary">
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <button onClick={handleEditClick} className="editBtnUser">
                        Edit
                      </button>
                    )}
                  </div>
                </div>
                <div>
                  <form onSubmit={handleSaveUpdate}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="userDetailText">
                          <div className="form-group pt-2">
                            <div className="tipModeDFlex">
                              <div className="settingFixedAmount settingTextSubPara">IdealTip ($):</div>
                              <div className="col-lg-3 settingTextSubPara" style={{ width: '22%' }}>
                              <input
                                type="text"
                                className="form-control"
                                value={tipModes?.tip_amount || ""}
                                placeholder="Your recommended tip amount for QR Code Scan."
                                onChange={handleFixedTipAmountChange}
                                disabled={!isEditingTip}
                                style={{ width: "100px", height: '40px' }} />
                                </div>
                                <div className="col-lg-3 settingFixedAmount settingTextSubPara" style={{ width: '15%' }}>
                                Tax ID :                                </div>
                                <div className="col settingTextSubPara" style={{ width: '100%' }}>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={tipModes?.tax_id || ""}
                                    placeholder="Tax Id."
                                    onChange={handletax_idChange}
                                    disabled={!isEditingTip}
                                    style={{ width: "100%", height: '40px' }} />
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="userDetailText">
                          {/* <div className="form-group pt-2"> */}
                            <div className="tipModeDFlex">
                              <div className="settingFixedAmount settingTextSubPara">Exp.No.of Tip :
                              </div>
                              <div className="col-lg-3 settingTextSubPara" style={{ width: '22%' }}>
                                     <input
                                type="text"
                                className="form-control"
                                value={tipModes?.expected_number_of_tip || ""}
                                placeholder="Tip"
                                onChange={handleExpectedNumberOfTipChange}
                                disabled={!isEditingTip}
                                style={{ width: "100px", height: '40px' }} /> 
                                </div>
                                <div className="col-lg-3 settingFixedAmount settingTextSubPara" style={{ width: '15%' }}>
                                Message :
                                </div>
                                <div className="col settingTextSubPara" style={{ width: '100%' }}>
                                <textarea
                                        className="form-control"
                                        value={tipModes?.receipt_message || ""}
                                        placeholder="Receipt message..."
                                        onChange={handleFixedReceiptMessageChange}
                                        disabled={!isEditingTip}
                                        style={{ width: "100%", height:"80px",resize: "vertical" }} 
                                    />
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    <div className="row" style={{ display: 'flex' }}>
                      <div className="col-lg-3 settingTextSubPara" style={{ width: '22%' }}>
                        Tip Methods:
                      </div>
                      <div className="tipMethodSetting">
                        <div className="userDetailText">
                          <div className="form-group  tipModeDFlex">
                            <div className="tipModeAliment">
                              <div className="form-check checkBoxAlimentReader tabletViewCheckBoxAliment tabletViewMargin" style={{ padding: '0px' }}>
                                <label className="form-check-label tipMethodTextLabel" htmlFor="qrcode">QRCode</label>
                                <input className="form-check-input"
                                  type="checkbox"
                                  name="qr_tip_amount"
                                  checked={tipModes?.qr_tip_amount}
                                  onChange={handleTipModeChange}
                                  disabled={!isEditingTip} />
                              </div>
                            </div>
                          </div>
                          <div className="form-group  tipModeDFlex">
                            <div className="tipModeAliment">
                              <div className="form-check checkBoxAlimentReader tabletViewMargin" style={{ padding: '0px' }}>
                                <label className="form-check-label tipMethodTextLabel" htmlFor="PaymentReader">PaymentReader</label>
                                <input className="form-check-input"
                                  type="checkbox"
                                  name="pr_tip_amount"
                                  checked={tipModes?.pr_tip_amount}
                                  onChange={handleTipModeChange}
                                  disabled={!isEditingTip} />
                              </div>
                            </div>
                          </div>
                          <div className="form-group  tipModeDFlex">
                            <div className="tipModeAliment">
                              <div className="form-check checkBoxAlimentReader tabletViewMargin" style={{ padding: '0px' }}>
                                <label className="form-check-label tipMethodTextLabel" htmlFor="MobileAsReader">MobileAsReader</label>
                                <input className="form-check-input"
                                  type="checkbox"
                                  name="mr_tip_amount"
                                  checked={tipModes?.mr_tip_amount}
                                  onChange={handleTipModeChange}
                                  disabled={!isEditingTip} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> 
                  </form>
                </div>
                <div className="row">
                  <div className="col-lg-3 settingTextSubPara" style={{ width: '21%' }}>QR Code:</div>
                  {settingData?.community_qr_code_url ?
                    <div className="col-lg-3">
                      {settingData?.community_qr_code_url ? (
                        <div onClick={() => setModalOpen(true)} style={{ cursor: "pointer" }}>
                          <img
                            src={settingData?.community_qr_code_url || "NA"}
                            alt="QR Code"
                            className="qr-code-image"
                            style={{ maxWidth: "100px", height: "auto" }}
                          />
                        </div>
                      ) : (
                        "NA"
                      )}
                      {isModalOpen && (
                        <div className="popup-overlay1" onClick={() => setModalOpen(false)}>
                          <div className="popup-modal1" onClick={(e) => e.stopPropagation()}>
                            <img
                              src={settingData?.community_qr_code_url || "NA"}
                              alt="QR Code"
                              style={{ maxWidth: "300px", height: "auto" }}
                            />
                            <button className="close-button1" onClick={() => setModalOpen(false)}>
                              &times;
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                    : <div className="col-lg-5">
                      <button className="generateNewQrCodeBtn" onClick={generateNewQRCodeCommunity}>Generate QR Code</button>
                    </div>
                  } 
                </div>
              </div>

              <div style={{ marginTop: '15px', marginBottom: '15px' }}>
                <div className="imageUploadSection">
                  <div className="imageUploadSectionFirst">
                    <div className="communityLogoImage">
                      <h5 className="settingHeading">Community Branding</h5>
                      <div className="uploadBox">
                        <label htmlFor="file-upload-logo" className="custom-file-upload">
                          Community Logo
                        </label>
                        <input
                          id="file-upload-logo"
                          type="file"
                          onChange={handleFileChange}
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                        />
                        <button className="editBtnUser" onClick={handleUploadCommunityLogo}>Upload</button>
                      </div>
                      <div>
                        {selectedFile && <div className="selectedFileShowName">Selected file: {selectedFile?.name}</div>}
                        <span className="selectedSizeLogo">File size should not exceed 160px in width and 45px height. This will replace SimplyTip logo!</span>
                      </div>
                    </div>
                    <div className="loginBgImage pt-5">
                      <h5 className="settingHeading">Upload image for login screen</h5>
                      <div className="uploadBoxBg">
                        <label htmlFor="file-upload-bg-image" className="custom-file-upload">
                          Login Page Background
                        </label>
                        <input
                          id="file-upload-bg-image"
                          type="file"
                          onChange={handleFileBgImageChange}
                          ref={fileInputBgRef}
                          style={{ display: 'none' }}
                        />
                        <button className="editBtnUser" onClick={handleUploadCommunityBgImage}>Upload</button>
                      </div>
                      <div>
                        {selectedBgFile && <div className="selectedFileShowName">Selected file: {selectedBgFile?.name}</div>}
                        <span className="selectedSizeLogo">File size will be max width 1920px and max height 1080px. This image will be displayed on the login screen of the portal. </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="SettingDetail" style={{ marginTop: '15px' }}>
                <div className="deleteCommunitySection">
                  <div className="settingHeading" style={{ paddingBottom: '15px' }}>Delete Community</div>
                  <div className="deleteComm">
                    <div className="settingTextSubPara">Discontinue Community:</div>
                    <div>
                      <button input="button" className="btn btn-outline-danger  btn-lg" style={{ width: '100px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={handleDelete}>Delete</button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="sectionSecondSetting">
              <div className="mainPayoutSection">
                <div className="settingBankAccountDetail">
                  <div className="payoutConfig">
                    <div className="headerCommonSetting"><h5 className="settingHeading">Payout Distribution</h5></div>
                    {isEditing ? (
                      <div className="saveCancel">
                        <button onClick={handleSubmitConfigure} className="editBtnUser saveBtnAlign" style={{ position: 'relative', right: '20px' }}>Save</button>
                        <button onClick={handleCancelEditConfigure} className="btn btn-secondary">Cancel</button>
                      </div>
                    ) : (
                      <button onClick={handleEditClickConfigure} className="editBtnUser">Edit</button>
                    )}
                  </div>
                  <form onSubmit={handleSubmitPayoutConfig}>
                    <div className="row pt-3">
                      <div className="subPayoutConfig">
                        <div className="payoutLeftLabel settingTextSubPara">
                          Payout Managed by:
                        </div>
                        <div className="checkBoxPayoutConfig">
                          <div style={{ width: '15%' }}>
                            <div className="form-check" >
                              <input className="form-check-input" type="radio" name="managed_by" value="requiteplatform" checked={payoutConfigModes?.managed_by === "requiteplatform"}
                                onChange={handleRadioChange} disabled={!isEditing} />
                            </div>
                          </div>
                          <div className="settingTextSubPara">
                            Requite Platform
                          </div>
                        </div>
                        <div className="checkBoxPayoutConfig">
                          <div style={{ width: '15%' }}>
                            <div className="form-check" >
                              <input className="form-check-input" type="radio" name="managed_by" value="community" checked={payoutConfigModes?.managed_by === "community"}
                                onChange={handleRadioChange} disabled={!isEditing} />
                            </div>
                          </div>
                          <div className="settingTextSubPara">
                            Community's Payroll
                          </div>
                        </div>
                      </div>
                      <div className="subPayoutConfig">
                        <div className="col-lg-12 settingTextSubPara" style={{ fontSize: '12px' }}>
                          <br></br>When Requite platform is used for tips distribution, each staff member's bank account must be provided to transfer Tip payouts.
                        </div>
                      </div>
                    </div>
                    <div className="row pt-3">
                      <div className="subPayoutConfig">
                        <div className="payoutLeftLabel settingTextSubPara">
                          Preferred Payout Method:
                        </div>
                        <div className="checkBoxPayoutConfig">
                          <div style={{ width: '15%' }}>
                            <div className="form-check" >
                              <input className="form-check-input" type="radio" name="distribution_rule" value="equalshare"
                                checked={payoutConfigModes?.distribution_rule === "equalshare"}
                                onChange={handleRadioChange} disabled={!isEditing} />
                            </div>
                          </div>
                          <div className="settingTextSubPara">
                            Equal Share
                          </div>
                        </div>
                        <div className="checkBoxPayoutConfig" style={{ width: '38%' }}>
                          <div style={{ width: '13%' }}>
                            <div className="form-check" >
                              <input className="form-check-input" type="radio" name="distribution_rule"
                                checked={payoutConfigModes?.distribution_rule === "yougetyourcollection"} value="yougetyourcollection"
                                onChange={handleRadioChange} disabled={!isEditing} />
                            </div>
                          </div>
                          <div className="settingTextSubPara">
                            You get your collection
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="subPayoutConfig">
                        <div className="payoutLeftLabel">
                        </div>
                        <div className="checkBoxPayoutConfig">
                          <div style={{ width: '15%' }}>
                            <div className="form-check" >
                              <input className="form-check-input" type="radio" name="distribution_rule"
                                checked={payoutConfigModes?.distribution_rule === "Custom"} value="Custom"
                                onChange={handleRadioChange} disabled={!isEditing} />
                            </div>
                          </div>
                          <div className="settingTextSubPara">
                            Custom
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-3">
                      <div className="subPayoutConfig">
                        <div className="payoutLeftLabel settingTextSubPara">
                          Preferred Frequency:
                        </div>
                        <div className="checkBoxPayoutConfig">
                          <div style={{ width: '15%' }}>
                            <div className="form-check" >
                              <input className="form-check-input" type="radio" name="frequency" value="weekly" checked={payoutConfigModes?.frequency === "weekly"} onChange={handleRadioChange} disabled={!isEditing} />
                            </div>
                          </div>
                          <div className="settingTextSubPara">
                            Weekly
                          </div>
                        </div>
                        <div className="checkBoxPayoutConfig">
                          <div style={{ width: '15%' }}>
                            <div className="form-check" >
                              <input className="form-check-input" type="radio" name="frequency" value="fortnight" checked={payoutConfigModes?.frequency === "fortnight"} onChange={handleRadioChange} disabled={!isEditing} />
                            </div>
                          </div>
                          <div className="settingTextSubPara">
                            Fortnight
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="subPayoutConfig">
                        <div className="payoutLeftLabel">
                        </div>
                        <div className="checkBoxPayoutConfig">
                          <div style={{ width: '15%' }}>
                            <div className="form-check" >
                              <input className="form-check-input" type="radio" name="frequency" value="manual" checked={payoutConfigModes?.frequency === "manual"} onChange={handleRadioChange} disabled={!isEditing} />
                            </div>
                          </div>
                          <div className="settingTextSubPara">
                            Manual
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

              </div>

              <div className="addressSectionSetting11">
                <div className="addressSectionSetting">
                  <AddressDetail />
                </div>
              </div>

              <div className="bankDetailSection" style={{ marginTop: '15px' }}>
                <div className="bankDetailInfo">
                  {

                  }
                  <div className="headerCommonSetting">
                    <div>
                      <h5 className="settingHeading">Bank Details</h5>
                    </div>
                    {settingData?.bank_details?.length > 0 ? "" :
                      <div>
                        <button className="editBtnUser saveBtnAlign" style={{ position: 'relative', left: '1px' }} onClick={addBankCommunity}>
                          Add
                        </button>
                      </div>
                    }
                  </div>
                  <div className="settingTextSubPara">All tips are collected in this account</div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="userDetailPayoutConfig">
                        <div className="form-group pt-2">
                          <div className="tipModeDFlex">
                            <div className="settingFixedAmount settingTextSubPara" style={{ width: '30%' }}>Account Holder Name:</div>
                            <input
                              type="text"
                              className="form-control settingTextSubPara"
                              name=""
                              value={settingData?.bank_details?.[0]?.account_name || "NA"}
                              placeholder="Enter Account Holder Name"
                              onChange={handleChange}
                              disabled
                              style={{ width: "300px", fontSize: '14px', height: '40px' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="userDetailPayoutConfig">
                        <div className="form-group pt-2">
                          <div className="tipModeDFlex">
                            <div className="settingFixedAmount settingTextSubPara" style={{ width: '30%' }}>Bank Name:</div>
                            <input
                              type="text"
                              className="form-control settingTextSubPara"
                              name=""
                              value={settingData?.bank_details?.[0]?.bank_name || "NA"}
                              placeholder="Enter Bank Name"
                              onChange={handleChange}
                              disabled
                              style={{ width: "300px", fontSize: '14px', height: '40px' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="userDetailPayoutConfig">
                        <div className="form-group pt-2">
                          <div className="tipModeDFlex">
                            <div className="settingFixedAmount settingTextSubPara" style={{ width: '30%' }}>Account Number:</div>
                            <input
                              type="text"
                              className="form-control"
                              name=""
                              value={settingData?.bank_details?.[0]?.account_number || "NA"}
                              placeholder="Enter Account Number"
                              onChange={handleChange}
                              disabled
                              style={{ width: "300px", fontSize: '14px', height: '40px' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="userDetailPayoutConfig">
                        <div className="form-group pt-2">
                          <div className="tipModeDFlex">
                            <div className="settingFixedAmount settingTextSubPara" style={{ width: '30%' }}>Routing Number:</div>
                            <input type="text" className="form-control" value={settingData?.bank_details?.[0]?.routing_number || "NA"}
                              placeholder="Enter Routing Number"
                              onChange={handleChange} disabled
                              style={{ width: "300px", fontSize: '14px', height: '40px' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 className="settingHeadingImportant">Important Information:</h5>
                  <ul>
                    <li className="settingTextSubParaImportantInfo">All tips being collected via platform are deposited in the Bank account as single collated transaction.</li>
                    <li className="settingTextSubParaImportantInfo">The Deposits are done after deducting the platform fees.</li>
                    <li className="settingTextSubParaImportantInfo">The Tip payment consolidation happens daily. The money is deposited in Bank account within 24-36 hours generally.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>



          <div className="mb-3 mt-4">
            <div className="SettingDetail">
              {/* <div className="tipConfiguration">
                <div style={{display:'flex', justifyContent:'space-between'}}>
                 <div className="headerCommonSetting"><h5 className="settingHeading">Tip Configuration</h5></div>
                 <div style={{display:'flex',justifyContent:'flex-end'}}>
                    {isEditingTip ? (
                      <div className="saveCancel">
                        <button
                          onClick={handleSaveUpdate}
                          className="editBtnUser saveBtnAlign">
                          Save
                        </button>
                        <button onClick={handleCancelEdit} className="btn btn-secondary">
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <button onClick={handleEditClick} className="editBtnUser">
                        Edit
                      </button>
                    )}
                  </div>
                </div>
                <div>
                <form onSubmit={handleSaveUpdate}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="userDetailText">
                          <div className="form-group pt-2">
                            <div className="tipModeDFlex">
                              <div className="settingFixedAmount settingTextSubPara">IdealTip ($):</div>
                              <input
                                type="text"
                                className="form-control"
                                value={tipModes?.tip_amount || ""}
                                placeholder="Your recommended tip amount for QR Code Scan."
                                onChange={handleFixedTipAmountChange}
                                disabled={!isEditingTip}
                                style={{ width: "100px",height:'40px' }} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{display:'flex'}}>
                      <div className="col-lg-3 settingTextSubPara" style={{width:'22%'}}>
                            Tip Methods:
                      </div>
                      <div className="tipMethodSetting">
                        <div className="userDetailText">
                          <div className="form-group  tipModeDFlex">
                            <div className="tipModeAliment">
                            <div className="form-check checkBoxAlimentReader tabletViewCheckBoxAliment tabletViewMargin" style={{padding:'0px'}}>
                                <label className="form-check-label tipMethodTextLabel" htmlFor="qrcode">QRCode</label>
                                <input className="form-check-input"
                                  type="checkbox"
                                  name="qr_tip_amount"
                                  checked={tipModes?.qr_tip_amount}
                                  onChange={handleTipModeChange}
                                  disabled={!isEditingTip}/>
                              </div>
                            </div>
                          </div>
                          <div className="form-group  tipModeDFlex">
                            <div className="tipModeAliment">
                               <div className="form-check checkBoxAlimentReader tabletViewMargin" style={{padding:'0px'}}>
                                <label className="form-check-label tipMethodTextLabel" htmlFor="PaymentReader">PaymentReader</label>
                                <input className="form-check-input"
                                  type="checkbox"
                                  name="pr_tip_amount"
                                  checked={tipModes?.pr_tip_amount}
                                  onChange={handleTipModeChange}
                                  disabled={!isEditingTip} />
                              </div>
                            </div>
                          </div>
                          <div className="form-group  tipModeDFlex">
                            <div className="tipModeAliment">
                              <div className="form-check checkBoxAlimentReader tabletViewMargin" style={{padding:'0px'}}>
                                <label className="form-check-label tipMethodTextLabel" htmlFor="MobileAsReader">MobileAsReader</label>
                                <input className="form-check-input"
                                  type="checkbox"
                                  name="mr_tip_amount"
                                  checked={tipModes?.mr_tip_amount}
                                  onChange={handleTipModeChange}
                                  disabled={!isEditingTip} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="row">
                <div className="col-lg-3 settingTextSubPara" style={{width:'21%'}}>QR Code:</div>
                {settingData?.community_qr_code_url ?
                <div className="col-lg-6">
                    {settingData?.community_qr_code_url ? (
                      <div onClick={() => setModalOpen(true)} style={{ cursor: "pointer" }}>
                        <img
                          src={settingData?.community_qr_code_url || "NA"}
                          alt="QR Code"
                          className="qr-code-image"
                          style={{ maxWidth: "100px", height: "auto" }}
                        />
                      </div>
                    ) : (
                      "NA"
                    )}
                    {isModalOpen && (
                        <div className="popup-overlay1" onClick={() => setModalOpen(false)}>
                          <div className="popup-modal1" onClick={(e) => e.stopPropagation()}>
                            <img
                              src={settingData?.community_qr_code_url || "NA"}
                              alt="QR Code"
                              style={{ maxWidth: "300px", height: "auto" }}
                            />
                            <button className="close-button1" onClick={() => setModalOpen(false)}>
                              &times;
                            </button>
                          </div>
                        </div>
                      )}
                </div>
                : <div className="col-lg-5">
                  <button className="generateNewQrCodeBtn" onClick={generateNewQRCodeCommunity}>Generate QR Code</button>
                </div>
                  }
                </div>
              </div> */}
              {/* <div className="mainPayoutSection">
                  <div className="settingBankAccountDetail">
                  <div className="payoutConfig">
                    <div className="headerCommonSetting"><h5 className="settingHeading">Payout Distribution</h5></div>
                      {isEditing ? (
                        <div className="saveCancel">
                          <button onClick={handleSubmitConfigure} className="editBtnUser saveBtnAlign" style={{ position: 'relative', right: '20px' }}>Save</button>
                          <button onClick={handleCancelEditConfigure} className="btn btn-secondary">Cancel</button>
                        </div>
                      ) : (
                        <button onClick={handleEditClickConfigure} className="editBtnUser">Edit</button>
                      )}
                  </div>
                <form onSubmit={handleSubmitPayoutConfig}>
                 <div className="row pt-3">
                    <div className="subPayoutConfig">
                       <div className="payoutLeftLabel settingTextSubPara">
                        Payout Managed by:
                       </div>
                       <div className="checkBoxPayoutConfig">
                         <div style={{width:'15%'}}>
                            <div className="form-check" >
                            <input className="form-check-input" type="radio" name="managed_by"  value="requiteplatform" checked={payoutConfigModes?.managed_by === "requiteplatform"}
                              onChange={handleRadioChange} disabled={!isEditing}  />
                            </div>
                          </div>
                          <div className="settingTextSubPara">
                            Requite Platform
                          </div>
                       </div>
                       <div className="checkBoxPayoutConfig">
                          <div style={{width:'15%'}}>
                            <div className="form-check" >
                            <input className="form-check-input" type="radio" name="managed_by" value="community"  checked={payoutConfigModes?.managed_by === "community"}
                             onChange={handleRadioChange} disabled={!isEditing} />
                            </div>
                          </div>
                          <div className="settingTextSubPara">
                            Community's Payroll
                          </div>
                        </div>
                    </div>
                    <div className="subPayoutConfig">
                       <div className="col-lg-12 settingTextSubPara" style={{fontSize:'12px'}}>
                          <br></br>When Requite platform is used for tips distribution, each staff member's bank account must be provided to transfer Tip payouts.
                       </div>
                 </div>
                 </div>
                 <div className="row pt-3">
                    <div className="subPayoutConfig">
                       <div className="payoutLeftLabel settingTextSubPara">
                          Preferred Payout Method:
                       </div>
                       <div className="checkBoxPayoutConfig">
                         <div style={{width:'15%'}}>
                            <div className="form-check" >
                              <input className="form-check-input" type="radio" name="distribution_rule"  value="equalshare"
                                  checked={payoutConfigModes?.distribution_rule === "equalshare"}
                                  onChange={handleRadioChange} disabled={!isEditing} />
                            </div>
                          </div>
                          <div className="settingTextSubPara">
                            Equal Share
                          </div>
                       </div>
                       <div className="checkBoxPayoutConfig" style={{width:'38%'}}>
                          <div style={{width:'13%'}}>
                            <div className="form-check" >
                              <input className="form-check-input" type="radio" name="distribution_rule"
                                  checked={payoutConfigModes?.distribution_rule === "yougetyourcollection"} value="yougetyourcollection"
                                  onChange={handleRadioChange} disabled={!isEditing} />
                            </div>
                          </div>
                          <div className="settingTextSubPara">
                            You get your collection
                          </div>
                        </div>
                    </div>
                 </div>
                 <div className="row">
                    <div className="subPayoutConfig">
                       <div className="payoutLeftLabel">
                       </div>
                       <div className="checkBoxPayoutConfig">
                         <div style={{width:'15%'}}>
                            <div className="form-check" >
                              <input className="form-check-input" type="radio" name="distribution_rule"
                                  checked={payoutConfigModes?.distribution_rule === "Custom"} value="Custom"
                                  onChange={handleRadioChange} disabled={!isEditing} />
                            </div>
                          </div>
                          <div className="settingTextSubPara">
                            Custom
                          </div>
                       </div>
                    </div>
                 </div>
                 <div className="row pt-3">
                    <div className="subPayoutConfig">
                       <div className="payoutLeftLabel settingTextSubPara">
                         Preferred Frequency:
                       </div>
                       <div className="checkBoxPayoutConfig">
                         <div style={{width:'15%'}}>
                            <div className="form-check" >
                            <input className="form-check-input" type="radio" name="frequency" value="weekly" checked={payoutConfigModes?.frequency === "weekly"} onChange={handleRadioChange} disabled={!isEditing}  />
                            </div>
                          </div>
                          <div className="settingTextSubPara">
                           Weekly
                          </div>
                       </div>
                       <div className="checkBoxPayoutConfig">
                          <div style={{width:'15%'}}>
                            <div className="form-check" >
                            <input className="form-check-input" type="radio" name="frequency" value="fortnight" checked={payoutConfigModes?.frequency === "fortnight"} onChange={handleRadioChange} disabled={!isEditing} />
                            </div>
                          </div>
                          <div className="settingTextSubPara">
                           Fortnight
                          </div>
                        </div>
                    </div>
                 </div>
                 <div className="row">
                    <div className="subPayoutConfig">
                       <div className="payoutLeftLabel">
                       </div>
                       <div className="checkBoxPayoutConfig">
                         <div style={{width:'15%'}}>
                            <div className="form-check" >
                            <input className="form-check-input" type="radio" name="frequency" value="manual" checked={payoutConfigModes?.frequency === "manual"} onChange={handleRadioChange} disabled={!isEditing} />
                            </div>
                          </div>
                          <div className="settingTextSubPara">
                           Manual
                          </div>
                       </div>
                    </div>
                 </div>
                </form>
                </div>
                  <div className="addressSectionSetting">
                   <AddressDetail />
                  </div>
              </div> */}
            </div>
          </div>
          <div className="SettingDetail">
            {/* <div className="imageUploadSectionFirst">
                <div className="communityLogoImage">
                <h5 className="settingHeading">Community Branding</h5>
                <div className="uploadBox">
                  <label htmlFor="file-upload-logo" className="custom-file-upload">
                    Community Logo
                  </label>
                  <input
                    id="file-upload-logo"
                    type="file"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                  />
                  <button className="editBtnUser" onClick={handleUploadCommunityLogo}>Upload</button>
                </div>
                <div>
                {selectedFile && <div className="selectedFileShowName">Selected file: {selectedFile?.name}</div>}
                <span className="selectedSizeLogo">File size will be width max 160px and height max 45px. This will replace TapNTip logo!</span>
                </div>
                </div>
                <div className="loginBgImage pt-5">
                <h5 className="settingHeading">Upload image for login screen</h5>
                <div className="uploadBoxBg">
                  <label htmlFor="file-upload-bg-image" className="custom-file-upload">
                    Login Page Background
                  </label>
                  <input
                    id="file-upload-bg-image"
                    type="file"
                    onChange={handleFileBgImageChange}
                    ref={fileInputBgRef}
                    style={{ display: 'none' }}
                  />
                  <button className="editBtnUser" onClick={handleUploadCommunityBgImage}>Upload</button>
                </div>
                <div>
                {selectedBgFile && <div className="selectedFileShowName">Selected file: {selectedBgFile?.name}</div>}
                <span className="selectedSizeLogo">File size will be max width 1920px and max height 1080px. This image will be displayed on the login screen of the portal. </span>
                </div>
                </div>
              </div> */}
            {/* <div className="bankDetailInfo">
              <div className="headerCommonSetting"><h5 className="settingHeading">Bank Details</h5></div>
              <div className="settingTextSubPara">All tips are collected in this account</div>
              <div className="row">
                  <div className="col-lg-12">
                      <div className="userDetailPayoutConfig">
                      <div className="form-group pt-2">
                            <div className="tipModeDFlex">
                              <div className="settingFixedAmount settingTextSubPara" style={{width:'30%'}}>Account Holder Name:</div>
                              <input
                                type="text"
                                className="form-control settingTextSubPara"
                                name=""
                                value={settingData?.bank_details?.[0]?.account_name || "NA"}
                                placeholder="Enter Account Holder Name"
                                onChange={handleChange}
                                disabled
                                style={{ width: "300px",fontSize:'14px',height:'40px' }}  />
                            </div>
                        </div> 
                      </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                      <div className="userDetailPayoutConfig">
                        <div className="form-group pt-2">
                            <div className="tipModeDFlex">
                              <div className="settingFixedAmount settingTextSubPara" style={{width:'30%'}}>Bank Name:</div>
                              <input
                                type="text"
                                className="form-control settingTextSubPara"
                                name=""
                                value={settingData?.bank_details?.[0]?.bank_name || "NA"}
                                placeholder="Enter Bank Name"
                                onChange={handleChange}
                                disabled
                                style={{ width: "300px",fontSize:'14px',height:'40px' }} />
                            </div>
                        </div>
                      </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                      <div className="userDetailPayoutConfig">
                        <div className="form-group pt-2">
                            <div className="tipModeDFlex">
                              <div className="settingFixedAmount settingTextSubPara" style={{width:'30%'}}>Account Number:</div>
                              <input
                                type="text"
                                className="form-control"
                                name=""
                                value={settingData?.bank_details?.[0]?.account_number || "NA"}
                                placeholder="Enter Account Number"
                                onChange={handleChange}
                                disabled
                                style={{ width: "300px",fontSize:'14px',height:'40px' }}  />
                            </div>
                        </div>
                      </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                      <div className="userDetailPayoutConfig">
                        <div className="form-group pt-2">
                            <div className="tipModeDFlex">
                              <div className="settingFixedAmount settingTextSubPara" style={{width:'30%'}}>Routing Number:</div>
                              <input type="text" className="form-control" value={settingData?.bank_details?.[0]?.routing_number || "NA"}
                                placeholder="Enter Routing Number"
                                onChange={handleChange}  disabled
                                style={{ width: "300px",fontSize:'14px', height:'40px' }} />
                            </div>
                        </div>
                      </div>
                  </div>
                </div>
               <h5 className="settingHeadingImportant">Important Information:</h5>
                  <ul>
                    <li className="settingTextSubParaImportantInfo">All tips being collected via platform are deposited in the Bank account as single collated transaction.</li>
                    <li className="settingTextSubParaImportantInfo">The Deposits are done after deducting the platform fees.</li>
                    <li className="settingTextSubParaImportantInfo">The Tip payment consolidation happens daily. The money is deposited in Bank account within 24-36 hours generally.</li>
                  </ul>
            </div> */}
          </div>
          {/* <div className="SettingDetail" style={{marginTop:'15px'}}>
           <div className="deleteCommunitySection">
             <div className="settingHeading" style={{paddingBottom:'15px'}}>Delete Community</div>
             <div className="deleteComm">
                <div className="">Discontinue Community:</div> 
                <div>
                  <button input="button" className="btn btn-outline-danger  btn-lg" style={{width:'100px',height:'40px',display:'flex',justifyContent:'center',alignItems:'center'}}>Delete</button>
                </div> 
              </div>
           </div>
         </div>           */}
          <div style={{ marginTop: '20px' }}>
            <Footer />
          </div>
        </div>
        <PopupDialog
          open={isPopupOpen}
          onClose={handleCloseDialog}
          title="Onboard New Staff">
          <AddUserForm accessToken={accessToken} community_id={community_id} cancelHandler={handleCloseDialog} />
        </PopupDialog>
      </div>
    </>
  );
};

export default Settings;