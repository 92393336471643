import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
} from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import { getPayoutChartAction } from "../../redux/api/api";
import { subDays, format, addDays } from "date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const PayoutChart = ({ payoutTimePeriod, accessToken, community_id }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { payoutChartData } = useSelector((state) => state.PayoutChartStore);
  const [payoutData, setPayoutData] = useState();
  const [groupedData, setGroupedData] = useState([]);
  const currentDate = new Date();
  const currentDateUTC = new Date(
    Date.UTC(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate()
    )
  );
  const startDate = subDays(currentDateUTC, payoutTimePeriod - 1);
  const formattedStartDate = startDate.toISOString().split("T")[0];

  const fetchPayoutData = () => {
    setLoading(true);
    dispatch(
      getPayoutChartAction({
        accessToken,
        community_id,
        trending_day: payoutTimePeriod,
      })
    ).finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchPayoutData();
  }, [accessToken, community_id, dispatch]);

  useEffect(() => {
    if (payoutChartData) {
      setPayoutData(payoutChartData);
    }
  }, [payoutChartData]);

  useEffect(() => {
    if (payoutChartData && payoutChartData?.length > 0) {
      const filteredData = payoutChartData?.filter(
        (item) => item?.status !== "delete" && item?.status !== "cancelled"
      );

      const grouped = filteredData?.reduce((acc, item) => {
        const date = new Date(item.created_ts);
        let groupDate;
        if (payoutTimePeriod >= 90) {
          const daysSinceStart = Math.floor(
            (date - startDate) / (1000 * 60 * 60 * 24)
          );
          const groupKey = Math.floor(daysSinceStart / 6) * 6;
          groupDate = format(addDays(startDate, groupKey), "yyyy-MM-dd");
        } else if (payoutTimePeriod >= 30) {
          const daysSinceStart = Math.floor(
            (date - startDate) / (1000 * 60 * 60 * 24)
          );
          const groupKey = Math.floor(daysSinceStart / 2) * 2;
          groupDate = format(addDays(startDate, groupKey), "yyyy-MM-dd");
        } else {
          groupDate = format(date, "yyyy-MM-dd");
        }

        if (!acc[groupDate]) {
          acc[groupDate] = {
            date: groupDate,
            tipCount: 0,
            netAmount: 0,
          };
        }

        acc[groupDate].tipCount += 1;
        acc[groupDate].netAmount += item.net;

        return acc;
      }, {});

      const allDates = [];
      const startDateObj = new Date(formattedStartDate);

      if (payoutTimePeriod >= 90) {
        let dayStart = 0;
        while (addDays(startDateObj, dayStart) <= currentDate) {
          const dayStartStr = format(
            addDays(startDateObj, dayStart),
            "yyyy-MM-dd"
          );
          if (!grouped[dayStartStr]) {
            grouped[dayStartStr] = {
              date: dayStartStr,
              tipCount: 0,
              netAmount: 0,
            };
          }
          allDates.push(dayStartStr);
          dayStart += 6;
        }
      } else if (payoutTimePeriod >= 30) {
        let dayStart = 0;
        while (addDays(startDateObj, dayStart) <= currentDate) {
          const dayStartStr = format(
            addDays(startDateObj, dayStart),
            "yyyy-MM-dd"
          );
          if (!grouped[dayStartStr]) {
            grouped[dayStartStr] = {
              date: dayStartStr,
              tipCount: 0,
              netAmount: 0,
            };
          }
          allDates.push(dayStartStr);
          dayStart += 2;
        }
      }
      // Handle case for daily grouping
      else {
        for (
          let d = startDateObj;
          d <= currentDate;
          d.setDate(d.getDate() + 1)
        ) {
          const dateStr = format(d, "yyyy-MM-dd");
          if (!grouped[dateStr]) {
            grouped[dateStr] = {
              date: dateStr,
              tipCount: 0,
              netAmount: 0,
            };
          }
          allDates.push(dateStr);
        }
      }

      const sortedData = allDates
        .map((date) => grouped[date])
        .sort((a, b) => new Date(a.date) - new Date(b.date));

      setGroupedData(sortedData);
    }
  }, [payoutChartData, payoutTimePeriod]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!groupedData || groupedData.length === 0) {
    return <div>No data available</div>;
  }
  const labels = groupedData.map((data, index) => {
    const date = new Date(data.date);
    if (payoutTimePeriod >= 90) {
      const startDate = new Date(data.date);
      const endDate = addDays(startDate, 5);
      const label = `${format(startDate, "MMM dd")} - ${format(endDate, "dd")}`;
      if (index % 2 === 0) return label;
      return "";
    }

    if (payoutTimePeriod >= 30) {
      const startDate = new Date(data.date);
      const endDate = addDays(startDate, 1);
      const label = `${format(startDate, "MMM dd")} - ${format(endDate, "dd")}`;
      if (index % 2 === 0) return label;
      return "";
    }

    return format(date, "MMM dd");
  });

  const payoutNetAmount = groupedData?.map((data) => data.netAmount / 100);
  const tipsCount = groupedData?.map((data) => data.tipCount);

  const combinedData = {
    labels,
    datasets: [
      {
        label: "Payouts",
        data: payoutNetAmount,
        backgroundColor: "rgba(37, 205, 37, 0.3)",
        borderColor: "rgba(37, 205, 37, 0.3)",
        borderWidth: 1,
        type: "bar",
      },
      {
        label: "Tips Count",
        data: payoutNetAmount,
        fill: false,
        borderColor: "rgba(0, 174, 129, 1)",
        borderWidth: 2,
        borderDash: [5, 5],
        type: "line",
        pointHoverRadius: 6,
        tension: 0.3,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        type: "linear",
        position: "left",
        beginAtZero: true,
        ticks: {
          callback: (value) => `$${value}`,
        },
        grid: {
          color: "rgba(200, 200, 200, 0.2)",
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        displayColors: false,
        callbacks: {
          title: () => "",
          label: function (context) {
            const index = context.dataIndex;
            const count = `Payouts: ${tipsCount[index]}`;
            const amt = `Amount: $${groupedData[index].netAmount / 100}`;
            return [count, amt];
          },
        },
      },
    },
  };

  return (
    <div className="chart-container">
      <Bar data={combinedData} options={options} id="payout-bar-line-chart" />
    </div>
  );
};

export default PayoutChart;
