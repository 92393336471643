import React, { useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import SideBar from "../../components/SideBar/SideBar";
import "./tipssummarylist.css";
import Header from "../../components/Header/Header";
import SelectCommunity from "../../components/SelectCommunity/SelectCommunity";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import userinvite from "../../assets/images/userinvite.png";
import upicon from "../../assets/images/Up.png";
import PopupDialog from "../../components/Popup/PopupDialog";
import AddUserForm from "../../components/UserForm/AddUserForm";
import downloadcircle from "../../assets/images/downloadcircle.png";
import Footer from "../../components/Footer/Footer";
import { getallTipsSummary, getTipReceipt,tipShareReceiptAction } from "../../redux/api/api";
import { convertToUTC, convertToUTCUSA } from "../../Utility/Utility";
import arrowleft from "../../assets/images/arrow-left.png";
import { useReactToPrint } from "react-to-print";
import headerRequiteLogo from "../../assets/images/requite.png";
import { convertToUTCDate, convertToUTCTime } from "../../Utility/Utility";
import tapNTipLogo from "../../assets/images/tapNtipBlackLogo.png";
import printerIcon from "../../assets/images/printer_icon.png";
import DatePicker from "react-datepicker";
import Pagination from "../../components/Pagination/Pagination";

const TipsSummaryList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allTipsData, setAllTipsData] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const today = new Date().toISOString().split("T")[0];
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const accessToken = localStorage.getItem("accesstoken");
  const community_id = localStorage.getItem("selectedCommunity");
  const { tipsDataSummary } = useSelector((state) => state.GetAllTipsSummaryStore);
  const { tipListData } = useSelector((state) => state.GetTipReceiptStore);
  const [isModalOpenHistory, setModalOpenHistory] = useState(false);
  const [tipDataReceipt, setTipDataReceipt] = useState();
  const componentRef = useRef();
  const handleOpenDialog = () => setIsPopupOpen(true);
  const handleCloseDialog = () => setIsPopupOpen(false);
  const [receiptHtml, setReceiptHtml] = useState('');
  const [shareTipUrl, setShareTipUrl] = useState('');
  const [iframeError, setIframeError] = useState(false);

  const handleHomeClick = () => navigate("/dashboard");

  const fetchTipsSummary = (startDateUTCConvert, endDateUTCConvert) => {
    const systemTimeSpanOffset = new Date().getTimezoneOffset();
    setLoading(true);
    dispatch(
      getallTipsSummary({
        accessToken,
        community_id,
        startDateUTCConvert,
        endDateUTCConvert,
        timezoonoffset: systemTimeSpanOffset,
      })).unwrap()
      .then((data) => {
        setAllTipsData(data);
      })
      .catch((error) => {
        console.error("Error fetching tips summary:", error);
      })
      .finally(() => setLoading(false));
  };

  const handleApplyFilterNew = () => {
    if (!(startDate instanceof Date && !isNaN(startDate)) || !(endDate instanceof Date && !isNaN(endDate))) {
      console.error("Invalid date(s) selected");
      return;
    }
    if (startDate > endDate) {
      console.error("Start date cannot be later than end date");
      return;
    }
    const startDateFormatted = startDate?.toISOString().split("T")[0];
    const endDateFormatted = endDate?.toISOString().split("T")[0];
    fetchTipsSummary(startDateFormatted, endDateFormatted);
  };

  useEffect(() => {
    const startDateFormatted = today;
    const endDateFormatted = today;
    fetchTipsSummary(startDateFormatted, endDateFormatted);
  }, [accessToken, community_id, dispatch]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentListAllTips = allTipsData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(allTipsData.length / itemsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prevPage) => prevPage + 1);
  };

  const downloadSelectedData = () => {
    if (!allTipsData || allTipsData.length === 0) {
      alert("No data to download for the selected date range.");
      return;
    }
    const headers = [
      "Sr No",
      "Date/Time",
      "Mode of Payment",
      "Paid By",
      "For Amount",
      "Platform Fees",
      "Actual Credit",
      "Accepted By",
      "Comment",
    ];

    const csvRows = allTipsData.map((item, index) => [
      index + 1,
      convertToUTC(item.transaction_ts || "NA"),
      item.payment_read_method || "NA",
      "NA",
      `$${(item.amount / 100).toFixed(2) || "0.00"}`,
      `$${(item.total_fees_deducted / 100).toFixed(2) || "0.00"}`,
      `$${
        ((item.amount - item.total_fees_deducted) / 100).toFixed(2) || "0.00"
      }`,
      item.name || "NA",
      item.notes || "NA",
    ]);

    const csvContent = [
      headers.join(","),
      ...csvRows.map((row) => row.join(",")),
    ].join("\n");

    // Create CSV file and download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `tips_summary_${startDate}_to_${endDate}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // const handleRowClick = (tip_id) => {
  //   const systemTimeSpanOffset = new Date().getTimezoneOffset();
  //   dispatch(
  //     getTipReceipt({
  //       accessToken,
  //       community_id,
  //       tip_id: tip_id,
  //       timezoonoffset: systemTimeSpanOffset,
  //     })
  //   );
  //   setModalOpenHistory(true);
  // };
      const handleRowClick = async (tip_id) => {
        if (accessToken) {
          try {
            // Dispatch the action to Redux
            const response = await dispatch(
              tipShareReceiptAction({
                accessToken,
                payload: {
                  tip_id,
                  email: "user@example.com",
                  mobile: "string"
                }
              })
            );
            if (response?.payload?.['share-tip-url']) {
              setShareTipUrl(response.payload['share-tip-url']);
              setModalOpenHistory(true);
            }
          } catch (error) {
            console.error('Error fetching receipt:', error);
          }
        }
      };

  useEffect(() => {
    if (tipListData) {
      setTipDataReceipt(tipListData);
    }
  }, [tipListData]);

  const handleSavePdf = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Qr_Code_${community_id || "Unknown"}.pdf`,
  });

  return (
    <>
      {tipsDataSummary ? (
        <div className="tipsSummaryMainPage mainLayoutPage">
          <ToastContainer />
          <SideBar />
          <div
            className="dashboard-container"
            style={{
              marginTop: "10px",
              marginRight: "10px",
              marginBottom: "10px",
              backgroundColor: "#f6f6f3",
              borderRadius: "20px",
            }}>
            <div className="header">
              <div>
                <div className="titleheading-content">
                  All Tips
                  <div className="subtitle">
                    <div className="home-text" onClick={handleHomeClick}>
                      <img src={upicon} alt="upIcon" /> Home /
                    </div>
                    <div className="current-page-text"> Tips</div>
                  </div>
                </div>
                <div className="tabletScreenHeader">
                  <Header />
                </div>
              </div>
              <div className="dropdownMain mainDropdownTablet">
                <div className="mediumScreenHeader">
                  <div className="header-button">
                    <SelectCommunity />
                  </div>
                  <button className="onboard-button" onClick={handleOpenDialog}>
                    <span>Onboard New Staff</span>
                    <img src={userinvite} alt="logoUser" />
                  </button>
                </div>
              </div>
            </div>
            <div className="top-line"></div>
            <div>
              <div className="mainTableHeaderTipsAll">
                <div className="deviceFilterSection">
                  <div className="choseDateRangeTips">Choose Date Range:</div>
                  <div className="date-range-picker">
                    <div>
                      <DatePicker
                        className="customDate"
                        selected={startDate}
                        onChange={(date) => setStartDate(date || new Date())}
                        dateFormat="MM/dd/yyyy"
                        maxDate={new Date()}
                        placeholderText="MM/dd/yyyy"
                      />
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      To
                    </div>
                    <div>
                      <DatePicker
                        className="customDate"
                        selected={endDate}
                        onChange={(date) => setEndDate(date || new Date())}
                        dateFormat="MM/dd/yyyy"
                        maxDate={new Date()}
                        placeholderText="MM/dd/yyyy"
                      />
                    </div>
                  </div>
                  <div className="buttonAndDownload">
                    <div>
                      <button
                        className="applyButton"
                        onClick={handleApplyFilterNew}>
                        Apply
                      </button>
                    </div>
                    <div
                      className="downloadIconAllTips"
                      onClick={downloadSelectedData}>
                      <img
                        src={downloadcircle}
                        alt="downloadcircle"
                        style={{
                          width: "30px",
                          height: "30px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {loading ? (
                <div className="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                <div className="table-responsive deviceTable">
                  <table className="table tablebordered table-hover mt-2">
                    <thead className="table-header">
                      <tr>
                        <th scope="col" style={{width:'5%'}}>Sr No</th>
                        <th scope="col">Transaction Date/Time</th>
                        <th scope="col">Mode of Payment</th>
                        <th scope="col">Paid By</th>
                        <th scope="col">Service Rating</th>
                        <th scope="col">Tip Amount (Credit Amount)</th>
                        <th scope="col">Accepted By</th>
                        <th scope="col">Comment</th>
                        <th scope="col">Receipt</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentListAllTips && currentListAllTips?.length > 0 ? (
                        currentListAllTips?.map((item, index) => (
                          <tr key={item?.tip_id} onClick={() => handleRowClick(item?.tip_id)} style={{cursor:'pointer'}}>
                            <td className="td-default-device" style={{width:'5%'}}>{index + 1 + indexOfFirstItem}</td>
                            <td className="td-default-device">{convertToUTCUSA(item?.transaction_ts || "NA")}</td>
                            <td className="td-default-device">
                              {item.payment_read_method === "QRCode" ? (
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="30px"
                                    viewBox="0 -960 960 960"
                                    width="30px"
                                    fill="#5f6368">
                                    <path d="M520-120v-80h80v80h-80Zm-80-80v-200h80v200h-80Zm320-120v-160h80v160h-80Zm-80-160v-80h80v80h-80Zm-480 80v-80h80v80h-80Zm-80-80v-80h80v80h-80Zm360-280v-80h80v80h-80ZM173.33-653.33h133.34v-133.34H173.33v133.34ZM120-600v-240h240v240H120Zm53.33 426.67h133.34v-133.34H173.33v133.34ZM120-120v-240h240v240H120Zm533.33-533.33h133.34v-133.34H653.33v133.34ZM600-600v-240h240v240H600Zm80 480v-120h-80v-80h160v120h80v80H680ZM520-400v-80h160v80H520Zm-160 0v-80h-80v-80h240v80h-80v80h-80Zm40-200v-160h80v80h80v80H400Zm-190-90v-60h60v60h-60Zm0 480v-60h60v60h-60Zm480-480v-60h60v60h-60Z" />
                                  </svg>
                                </div>
                              ) : (
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="30px"
                                    viewBox="0 -960 960 960"
                                    width="30px"
                                    fill="#5f6368">
                                    <path d="M880-733.33v506.66q0 27-19.83 46.84Q840.33-160 813.33-160H146.67q-27 0-46.84-19.83Q80-199.67 80-226.67v-506.66q0-27 19.83-46.84Q119.67-800 146.67-800h666.66q27 0 46.84 19.83Q880-760.33 880-733.33ZM146.67-634h666.66v-99.33H146.67V-634Zm0 139.33v268h666.66v-268H146.67Zm0 268v-506.66 506.66Z" />
                                  </svg>
                                </div>
                              )}
                            </td>
                            <td className="td-default-device">NA</td>
                            <td className="td-default-device">
                              {item?.rating || "NA"}
                            </td>
                            <td className="td-default-device">
                              ${item?.amount / 100 || 0} ($
                              {(item?.amount - item?.total_fees_deducted) /
                                100 || 0}
                              )
                            </td>
                            <td className="td-default-device">
                              {item?.name || "NA"}
                            </td>
                            <td className="td-default-device">
                              {item?.notes || "NA"}
                            </td>
                            <td className="td-default-device">
                              <img
                                src={arrowleft}
                                alt="arrowleft"
                                style={{ width: "20px", height: "20px" }}
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan="11"
                            style={{ textAlign: "center", padding: "20px" }}>
                            No transactions found for given criteria.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}

              <>
              {isModalOpenHistory && shareTipUrl && (
                        <div className="popup-overlay-receipt" onClick={() => setModalOpenHistory(false)}>
                          <div className="popup-modal-receipt" onClick={(e) => e.stopPropagation()}>
                            <div className="modal-header-receipt"></div>
                            <div className="popupreceiptContainer">
                              <iframe
                                src={shareTipUrl}
                                width="100%"
                                height="650px"
                                overflowY= "auto" // Enables vertical scrolling
                                scrollbarWidth= "thin" // Firefox
                                
                                scrollbarColor= "#888 #f1f1f1"
                                style={{ border: "none", }}
                                
                                onError={() => setIframeError(true)}
                              />
                              {iframeError && <p>Error loading receipt. Please <a href={shareTipUrl} target="_blank">click here</a>.</p>}
                            </div>
                          </div>
                        </div>
                      )}
              </>
              <div className="pagination mb-4">
                <Pagination 
                 currentPage={currentPage}
                 totalPages={totalPages}
                 onPrevious={handlePreviousPage}
                 onNext={handleNextPage}
                />  
              </div>
            </div>
            <Footer />
          </div>
        </div>
      ) : (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
      <PopupDialog
        open={isPopupOpen}
        onClose={handleCloseDialog}
        title="Onboard New Staff">
        <AddUserForm
          accessToken={accessToken}
          community_id={community_id}
          cancelHandler={handleCloseDialog}
        />
      </PopupDialog>
    </>
  );
};

export default TipsSummaryList;
