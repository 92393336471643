import { configureStore } from '@reduxjs/toolkit';
import LoginUserSlice from '../reducer/LoginUserSlice/LoginUserSlice';
import LoginWithMobileSlice from "../reducer/loginWithMobile/LoginWithMobile"
import otpSlice from "../reducer/OtpReducer/OtpReducer"
import memberInfoSlice from "../reducer/MemberInfo/MemberInfo"
import accountStatusSlice from "../reducer/StripeAccountInfo/StripeAccountInfo"
import StripeOnBoard from "../reducer/getCommunityList/GetCommunityList"
import communityListSlice from "../reducer/getCommunityList/GetCommunityList"
import addCommunityProfessionalSlice from "../reducer/addCommunityProfessional/addCommunityProfessional"
import UpdateUserSlice from "../reducer/UpdateUserDetails/UpdateUserDetails"
import ActiveInactiveStaffUserSlice from "../reducer/ActiveInactiveStaffUser/ActiveInactiveStaffUser"
import AuthRefreshTokenSlice from "../reducer/RefreshToken/RefreshToken"
import UpdateMemberInfoSlice from "../reducer/UpdateMemberInfo/UpdateMemberInfo"
import GetPaymentListSlice from "../reducer/getPaymentList/GetPaymentList"
import GetDashboardPaymentSlice from "../reducer/getDashboardPayment/GetDashboardPayment"
import tipSummaryHistorySlice from "../reducer/TipSummaryHistory/TipSummaryHistory"
import GetCommunityReaderSlice from "../reducer/getCommunityReader/GetCommunityReader"
import ActiveInactiveDeviceSlice from "../reducer/ActiveInactiveDevice/ActiveInactiveDevice"
import addDeviceSlice from "../reducer/addDevice/addDevice"
import UpdateReaderSlice from "../reducer/UpdateReader/UpdateReader"
import SettingsSlice from "../reducer/SettingsReducer/SettingReducer"
import UpdateCommunityTipAmountSlice from "../reducer/UpdateCommunityTipAmount/UpdateCommunityTipAmount"
import ReaderHistorySlice from "../reducer/ReaderHistoryReducer/ReaderHistoryReducer"
import GetCommunityConnectedReaderSlice from "../reducer/CommunityReader/GetCommunityConnectedReader"
import GetTotalDeviceSlice from "../reducer/getTotalDeviceState/GetTotalDeviceState"
import ManagePayoutSlice from "../reducer/ManagePayout/ManagePayout"
import UpdatedSettingPayoutConfigSlice from "../reducer/UpdateSettingPayoutConfig/UpdateSettingPayoutConfig"
import GetBankDetailParticularUserSlice from "../reducer/GetBankDetailParticularUser/GetBankDetailParticularUser"
import AddNewBankAccountSlice from "../reducer/AddBankAccount/AddBankAccount"
import GetBankDetailSingleProfileSlice from "../reducer/GetBankDetailSingleProfile/GetBankDetailSingleProfile"
import CommunityLogoSlice from "../reducer/CommunityLogo/CommunityLogo"
import CommunityBgImageSlice from "../reducer/CommunityBgImage/CommunityBgImage"
import ProfilePictureUploadSlice from "../reducer/ProfilePictureUpload/ProfilePictureUpload"
import GenerateQrCodeCommunitySlice from "../reducer/GenerateQrCodeCommunity/GenerateQrCodeCommunity"
import GetCommunityMemberSlice from "../reducer/GetCommunityMember/GetCommunityMember"
import GetAllTipsSummarySlice from "../reducer/GetAllTipsSummary/GetAllTipsSummary"
import GetAddressDetailSlice from "../reducer/GetAddressDetail/GetAddressDetail"
import GetHelpAndSupportSlice from "../reducer/GetHelpAndSupport/GetHelpAndSupport"
import GetTipReceiptSlice from "../reducer/GetTipReceipt/GetTipReceipt"
import GetPayoutSlice from "../reducer/GetEligiblePayout/GetEligiblePayout"
import PreviewPayoutSlice from "../reducer/PreviewPayout/PreviewPayout"
import PayoutListHistorySlice from "../reducer/GetPayoutListHistory/GetPayoutListHistory"
import PayoutChartSlice from "../reducer/GetPayoutChart/GetPayoutChart"
import GetCommunityTipSummarySlice from "../reducer/GetCommunitySummaryChartTrend/GetCommunitySummaryChartTrend"
import DeletePayoutSlice from "../reducer/DeletePayout/DeletePayout"
import IndividualStaffPayoutSlice from "../reducer/GetPayoutStaffIndividualList/GetPayoutStaffIndividualList"
import PartialPayoutDataSlice from "../reducer/GetPartialPayout/GetPartialPayout"
import AddAddressSlice from "../reducer/AddAddress/AddAddress"
import AddCommunityBankAccountSlice from "../reducer/AddCommunityBankAccount/AddCommunityBankAccount"
import AccountOnBoardingSlice from "../reducer/AccountOnBoarding/AccountOnBoarding"
import staffBankDetailUpdateSlice from "../reducer/StaffBankDetailUpdate/StaffBankDetailUpdate"
import CommunityBankAccountSlice from "../reducer/CommunityBankAccountAdd/CommunityBankAccountAdd"
import TipShareReceiptSlice from "../reducer/TipShareReceipt/TipShareReceipt"


const store = configureStore({
  reducer: {
    loginUser: LoginUserSlice, 
    LoginWithMobile:LoginWithMobileSlice,
    otpState:otpSlice,
    memberInfoStore:memberInfoSlice,
    accountStatus:accountStatusSlice,
    StripeOnBoardState:StripeOnBoard,
    ListOfCommunity:communityListSlice,
    CommunityProfessionalStore:addCommunityProfessionalSlice,
    UpdateUserDetailStore:UpdateUserSlice,
    ActiveInactiveStaffUser:ActiveInactiveStaffUserSlice,
    RefreshTokenStore:AuthRefreshTokenSlice,
    UpdateMemberInfoStore:UpdateMemberInfoSlice,
    GetPaymentListStore:GetPaymentListSlice,
    GetDashboardPaymentStore:GetDashboardPaymentSlice,
    tipSummaryHistoryStore:tipSummaryHistorySlice,
    GetCommunityReaderStore:GetCommunityReaderSlice,
    ActiveInactiveDeviceStore:ActiveInactiveDeviceSlice,
    addDeviceStore:addDeviceSlice,
    UpdateReaderStore:UpdateReaderSlice,
    SettingStore:SettingsSlice,
    UpdateCommunityTipAmountStore:UpdateCommunityTipAmountSlice,
    ReaderHistoryStore:ReaderHistorySlice,
    GetCommunityConnectedReaderStore:GetCommunityConnectedReaderSlice,
    GetTotalDeviceStore:GetTotalDeviceSlice,
    ManagePayoutStore:ManagePayoutSlice,
    UpdatedSettingPayoutConfigStore:UpdatedSettingPayoutConfigSlice,
    GetBankDetailParticularUserStore:GetBankDetailParticularUserSlice,
    AddNewBankAccountStore:AddNewBankAccountSlice,
    GetBankDetailSingleProfileStore:GetBankDetailSingleProfileSlice,
    CommunityLogoStore:CommunityLogoSlice,
    CommunityBgImageStore:CommunityBgImageSlice,
    ProfilePictureUploadStore:ProfilePictureUploadSlice,
    GenerateQrCodeCommunityStore:GenerateQrCodeCommunitySlice,
    GetCommunityMemberStore:GetCommunityMemberSlice,
    GetAllTipsSummaryStore:GetAllTipsSummarySlice,
    GetAddressDetailStore:GetAddressDetailSlice,
    GetHelpAndSupportStore:GetHelpAndSupportSlice,
    GetTipReceiptStore:GetTipReceiptSlice,
    GetPayoutStore:GetPayoutSlice,
    PreviewPayoutStore:PreviewPayoutSlice,
    PayoutListHistoryStore:PayoutListHistorySlice,
    PayoutChartStore:PayoutChartSlice,
    GetCommunityTipSummaryStore:GetCommunityTipSummarySlice,
    DeletePayoutStore:DeletePayoutSlice,
    IndividualStaffPayoutStore:IndividualStaffPayoutSlice,
    PartialPayoutDataStore:PartialPayoutDataSlice,
    AddAddressStore:AddAddressSlice,
    AddCommunityBankAccountStore:AddCommunityBankAccountSlice,
    AccountOnBoardingStore:AccountOnBoardingSlice,
    staffBankDetailUpdateStore:staffBankDetailUpdateSlice,
    CommunityBankAccountStore:CommunityBankAccountSlice,
    TipShareReceiptStore:TipShareReceiptSlice

  },

  
});

export default store;
